import { isPast } from '../utils/data';

import HoverDates from './hover_dates';
import Modal from './modal';
import PreviousStatusIndicators from './previous_status_indicators';

class EntryList {
  constructor(options) {
    this.root = options.root;
    this.scale = options.scale;
    this.rootWidth = options.rootWidth;
    this.experimentData = options.experimentData;
    this.layout = Object.assign({}, this.DEFAULT_LAYOUT, options.layout);
    this.entries = undefined;
  }

  writeDataToEntries = () =>
    this.root.selectAll("a").data(this.experimentData).enter().append("a")

  setEntryNames = () =>
    this.entries
      .append("div").attr("class", "timeline__entry-text-container").attr("id", (d) => `text-container--${d["key"]}`)
      .append("p").attr("class", "timeline__entry-text")
      .text(this.decoratedName)

  setEntryClasses = () =>
    this.entries.attr("class", (d) => {
      const baseClass = "timeline__entry";
      const displayPreviousStatusIndicators =
        isPast(d["starts_at"]) && $("#timeline-root").data("display-previous-status-indicators")

      let hueClass = `timeline__entry--${d["status"].toLowerCase()}`;
      if (displayPreviousStatusIndicators) {
        hueClass = "timeline__entry--running";
      }

      return `${baseClass} ${hueClass}`;
    })

  entryWidth = (d) =>
    this.scale(d["ends_at"]) - this.scale(d["starts_at"])

  setEntryWidths = () => this.entries.style("width", (d) => `${this.entryWidth(d)}px`);

  setEntryPositions = () =>
    this.entries.style("margin-left", (d) => `${this.scale(d["starts_at"]) + this.layout.chartLeftMarginOffset}px`)

  modal = () => new Modal({ entries: this.entries })
  hoverDates = () => new HoverDates({ entries: this.entries })
  previousStatusIndicators = () => new PreviousStatusIndicators({ entries: this.entries, scale: this.scale })

  decoratedName(experiment) {
    if ('storefront_country_codes' in experiment && experiment.storefront_country_codes != "All") {
      return `${experiment.name} (${experiment.storefront_country_codes})`;
    }

    if ('treatments_count' in experiment) {
      return `${experiment.name} [${experiment.treatments_count}]`;
    }

    return experiment.name;
  }

  previousStatusesAvailable = () =>
    this.experimentData.some((d) => d.previous_status_periods && d.previous_status_periods.length)

  draw = () => {
    this.entries = this.writeDataToEntries();

    this.setEntryNames();
    this.setEntryClasses();
    this.setEntryWidths();
    this.setEntryPositions();

    this.hoverDates().draw();

    if (this.previousStatusesAvailable()) {
      this.previousStatusIndicators().draw();
    }

    this.modal().draw();
  }
}

export default EntryList;
