import { merge } from 'lodash';

class LineChartLegendClickHandler {
  constructor(options) {
    this.options = options;
    this.isInitialLegendClick = true;

    this.parentClass = ".apexcharts-legend-series";
    this.seriesClass = ".apexcharts-legend-text"
  }

  get dynamicYAxisOptions () {
    return { yaxis: merge({}, this.options.build().yaxis, { max: (d) => d, min: (d) => d }) }
  }

  call = (e, chartState) => {
    const toggledSeries = e.target.closest(this.parentClass)?.querySelector(this.seriesClass)?.innerText

    if (toggledSeries) {
      const series = Object.keys(chartState.w.config.lines);

      if (this.isInitialLegendClick) {
        chartState.updateOptions(this.dynamicYAxisOptions)
        this.isInitialLegendClick = false;
      }

      const userIsHoldingCommand = e.metaKey;

      if (userIsHoldingCommand) {
        series.forEach((s) => chartState.hideSeries(s))
      }

      chartState.toggleSeries(toggledSeries)

      const allSeriesToggledOff = series.every((s) => chartState.series.isSeriesHidden(s).isHidden);

      if (allSeriesToggledOff) {
        chartState.resetSeries();
        this.isInitialLegendClick = true;
      }
    }
  }
}

export default LineChartLegendClickHandler;
