import { DomLike } from "./dom_like"
import { WIDGET_FILTER_CHANGED, WIDGET_FILTER_VALUES_UPDATED, WIDGET_FILTERS_UPDATED, WIDGET_FILTER_TYPE_SELECTED, WIDGET_FILTER_TYPE_UNSELECTED } from "./events"

const WIDGET_FILTER_DROPDOWN_SELECTOR = '.widget-filter__select'
const WIDGET_FILTER_VALUES_DROPDOWN_SELECTOR = '.widget-filter-values__select'
const DESTROY_WIDGET_FILTER_INPUT_SELECTOR = 'input[type=hidden][name*=_destroy]'

export class WidgetFilter extends DomLike {
  constructor(container, resultFilter, populateData = false) {
    super(container)
    this.resultFilter = resultFilter

    this.$filtersDropdown = this.find(WIDGET_FILTER_DROPDOWN_SELECTOR)
    this.$valuesDropdown = this.find(WIDGET_FILTER_VALUES_DROPDOWN_SELECTOR)

    this.setupFilters()
    this.setupValues()
    this.setupActiveUnactive()

    if (populateData) {
      // `populateData` is true when adding a widget filter
      // after the page is loaded (ie, via the "add filter" link)
      // the following will populate its elements
      this.pub(WIDGET_FILTERS_UPDATED)
    }
  }

  get widgetFilterId() {
    return this.$filtersDropdown.val()
  }

  get dataSourceId() {
    return this.form.dataSourceId
  }

  get widgetFilters() {
    return this.form.widgetFilters
  }

  get form() {
    return this.resultFilter.form
  }

  get filterValuesUrlTemplate() {
    return this.form.filterValuesUrlTemplate
  }

  setupFilters() {
    this.sub(WIDGET_FILTERS_UPDATED, () => { this.populateFiltersData() })
    this.$filtersDropdown.on('change', () => { this.pub(WIDGET_FILTER_CHANGED) })
  }

  setupValues() {
    this.sub(WIDGET_FILTER_CHANGED, () => { this.updateFilterValues() })
    this.sub(WIDGET_FILTER_VALUES_UPDATED, (_, { data }) => this.setWidgetFilterValues(data) )
  }

  setupActiveUnactive() {
    this.sub(WIDGET_FILTER_TYPE_SELECTED, () => { this.unmarkAsDestroyable() })
    this.sub(WIDGET_FILTER_TYPE_UNSELECTED, () => { this.markAsDestroyable() })
  }

  populateFiltersData() {
    const options = this.toOptionsCollection(this.widgetFilters)
    this.$filtersDropdown.html(options)
    this.$filtersDropdown.trigger('change')
  }

  // Filter values could be cached
  updateFilterValues() {
    this.clearValues()

    const ids = [this.dataSourceId, this.widgetFilterId]

    // skip unless both a data source and a widget filter are selected
    if (ids.some((id) => !id)) return

    const url = this.filterValuesUrlTemplate.replaceAll('__id__', () => ids.shift())

    $.getJSON(url).then((data) => { this.pub(WIDGET_FILTER_VALUES_UPDATED, { data }) })
  }

  setWidgetFilterValues(values) {
    const options = this.toOptionsCollection(values)
    this.$valuesDropdown.html(options)
  }

  toOptionsCollection(data) {
    const options = [$('<option>')]

    for (const { value, text } of data) {
      options.push($('<option>').attr('value', value).text(text))
    }

    return options
  }

  clearValues() {
    this.setWidgetFilterValues([])
  }

  markAsDestroyable() {
    this.find(DESTROY_WIDGET_FILTER_INPUT_SELECTOR).val(1)
  }

  unmarkAsDestroyable() {
    this.find(DESTROY_WIDGET_FILTER_INPUT_SELECTOR).val(0)
  }
}
