import UrlManager from './url_manager';

export default class Component {
  constructor(props = {}) {
    if (props.hasOwnProperty('store')) {
      this.store = props.store;
    }

    if (props.hasOwnProperty('urlParameter')) {
      this.urlParameter = props.urlParameter;
    }

    if (props.hasOwnProperty('dataStorage')) {
      this.dataStorage = props.dataStorage;
    } else {
      this.dataStorage = UrlManager.getInstance();
    }
  }

  initialize() {
    return;
  }
}
