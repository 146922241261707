import Component from '../../store/lib/component.js';

export const URL_PARAMETER = 'filters';
export const FILTERS_WRAPPER = '#elc-filters';
export const DEFAULT_FILTER_OPERATOR = 'in';

export default class FiltersSelector extends Component {
  constructor(props = {}) {
    super({
      ...props,
      urlParameter: URL_PARAMETER
    });

    this.baseUrl = $(FILTERS_WRAPPER).data('filters-url');

    props.store.events.subscribe('populateFilters', () => this.populateFilters());
    props.store.events.subscribe('clearFilters', () => this.clearFilters());

    $(document).on('cocoon:after-insert', (_, insertedItem) => {
      $(insertedItem).find('.form-select.operator').on('change', (e) => {
        const $el = $(e.target);
        const key = $(e.target).parent().find('input:first-child').val();
        this.updateFilterOperator(key, $el.val());
        props.store.events.publish('fetchSamplesData');
      });

      $(insertedItem).find('.form-select[data-controller="form--select2"]').on('change', (e) => {
        const $el = $(e.target);
        const key = $(e.target).parent().find('input:first-child').val();
        this.updateFilterValues(key, $el.val());
        props.store.events.publish('fetchSamplesData');
      });

      $(insertedItem).on('select2:select select2:unselect', (e) => {
        const $el = $(e.target);
        const key = $(e.target).parent().find('input:first-child').val();
        this.updateFilterValues(key, $el.val());
        props.store.events.publish('fetchSamplesData');
      });
    });

    $(document).on('cocoon:before-remove', (e) => {
      this.deleteFilter($(e.target).find('input:first-child').val());
    });

    $(document).on('cocoon:after-remove', () => {
      props.store.events.publish('fetchSamplesData');
    });

    $(document).on('select2:select', '.add-widget-filter select', (e) => {
      const $widgetFilterSelect = $(e.currentTarget);
      const selectedFilterType = $widgetFilterSelect.val()[0];

      // trigger Cocoon to show the new filter
      if (selectedFilterType) {
        $(`a[data-widget-filter-key=${selectedFilterType}]`).trigger('click');
      }

      // clear selection from Filters select2
      $widgetFilterSelect.val(null).trigger('change');
    });
  }

  clearFilters() {
    this.store.commit('filters', {});
    this.dataStorage.delete(this.urlParameter);
    $(FILTERS_WRAPPER).html(null);
  }

  populateFilters() {
    const params = {
      experiment_calculator: {
        data_source_id: this.store.state.dataSource
      }
    };

    $.post(this.baseUrl, params).done((html) => {
      $(FILTERS_WRAPPER).html(html);

      this.recoverStateFromUrl();
    }).fail((response) => {
      this.store.events.publish('processErrors', response);
    });
  }

  initialize() {
    const val = JSON.parse(this.dataStorage.get(this.urlParameter));

    if (val != null && val != {}) {
      this.store.commit('filters', val);
    }
  }

  recoverStateFromUrl() {
    for (const [key, values] of Object.entries(this.store.state.filters)) {
      // Bring up the filter
      $(`a[data-widget-filter-key=${key}]`).trigger('click');

      // Select elements
      $('.widget-filters-list:first').find('.form-select.operator:last').each((_, el) => {
        $(el).val(values['operator']);
      });

      $('.widget-filters-list:first').find('.form-select.widget-filter-value:last').each((_, el) => {
        $(el).val(values['values']).trigger('change');
      });
    }

    this.store.events.publish('fetchSamplesData');
  }

  updateFilterOperator(key, operator) {
    let filters = this.store.state.filters;

    if (filters[key] != null) {
      filters[key] = { ...filters[key], ...{ operator } };
      this.store.commit('filters', filters);
    }

    this.buildJsonURL();
  }

  updateFilterValues(key, values) {
    if (Array.isArray(values) && values.length > 0) {
      let filters = this.store.state.filters;

      if (filters[key] == null) {
        filters[key] = { values, operator: DEFAULT_FILTER_OPERATOR };
      }

      filters[key] = { ...filters[key], ...{ values } };
      this.store.commit('filters', filters);
    } else {
      this.deleteFilter(key);
    }

    this.buildJsonURL();
  }

  deleteFilter(key) {
    let filters = this.store.state.filters;
    delete filters[key];

    this.store.commit('filters', filters);
    this.buildJsonURL();
  }

  buildJsonURL() {
    if($.isEmptyObject(this.store.state.filters)) {
      this.dataStorage.delete(this.urlParameter);
    } else {
      const params = JSON.stringify(this.store.state.filters);
      this.dataStorage.addOrModify(this.urlParameter, params);
    }
  }

}
