import { merge } from 'lodash';

import BaseOptions from './base_options';
import formatterFor from '../formatter_for';
import ScatterPlotTooltip from '../custom_tooltips/scatter_plot_tooltip';

class ScatterPlotOptions extends BaseOptions {
  static defaultOptions() {
    return merge(super.defaultOptions(), {
      chart: {
        type: 'scatter',
      },
      colors: ['var(--bs-blue)'],
      legend: {
        show: false
      },
      tooltip: {
        markers: {
          show: false
        }
      },
      xaxis: {
        tickAmount: 7,
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        tickAmount: 6
      }
    });
  }

  constructor(chartData) {
    super(chartData);

    this.tooltip = new ScatterPlotTooltip(this.chartData);
    this.dataDependentOptions = this.setDataDependentOptions();
  }

  setDataDependentOptions = () =>
  ({
    title: {
      text: this.chartData.meta.title_text
    },
    tooltip: {
      custom: this.tooltip.render
    },
    xaxis: {
      labels: {
        formatter: formatterFor(this.chartData.meta.first_measure_labels_format)
      },
      title: {
        text: this.chartData.meta.first_measure_title_text
      }
    },
    yaxis: {
      labels: {
        formatter: formatterFor(this.chartData.meta.second_measure_labels_format)
      },
      title: {
        text: this.chartData.meta.second_measure_title_text
      }
    },
  })
}

export default ScatterPlotOptions;
