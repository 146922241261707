class BaseWidget {
  constructor(attributes) {
    this.attributes = attributes;
    this.element = this.attributes.el;
    this.waypoint = this.initWaypoint();
    this.id = this.element.attr('id');
    this.loadingIcon = this.element.closest('.portlet').find('.portlet__icon.loading');
    this.defaultDataErrorMessage = "No data available";
  }

  initWaypoint = () =>
    new Waypoint({
      element: this.element,
      offset: '100%',
      handler: () => {
        this.refresh();
        this.waypoint.destroy();
      }
    })

  render = () => null;
  redraw = () => null;

  refresh = () =>
    $.when(this.loadingIcon.show())
      .then(() => this.load().done((data) => this.element.trigger('widget:loaded', data)))
      .always(() => this.loadingIcon.hide())

  renderDataErrorMessage = (message) =>
    this.element.append(`
      <div class="no-data-container">
        <div class="no-data">${message || this.defaultDataErrorMessage}</div>
      </div>
    `)

  setHtml = (html) => this.element.html(html)
}

export default BaseWidget;
