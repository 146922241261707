const colorsByLevel = {
  danger: {
    bg: 'var(--bs-danger-bg-subtle)',
    text: 'var(--bs-danger)'
  },
  info: {
    bg: 'var(--bs-info-bg-subtle)',
    text: 'var(--bs-info)'
  },
  success: {
    bg: 'var(--bs-success-bg-subtle)',
    text: 'var(--bs-success)'
  },
  warning: {
    bg: 'var(--bs-warning-bg-subtle)',
    text: 'var(--bs-warning)'
  }
}

const annotationsFor = (annotationsData) =>
  annotationsData?.map((annotation) => ({
    x: annotation.start_epoch,
    x2: annotation.end_epoch,
    borderColor: colorsByLevel[annotation.level].text,
    fillColor: colorsByLevel[annotation.level].bg,
    label: {
      text: annotation.title,
      borderColor: colorsByLevel[annotation.level].bg,
      style: {
        color: colorsByLevel[annotation.level].text,
        background: colorsByLevel[annotation.level].bg
      }
    }
  }))

export default annotationsFor;
