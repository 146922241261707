import formatterFor from '../formatter_for';

class ScatterPlotTooltip {
  constructor(chartData) {
    this.chartData = chartData;
    this.xAxisFormatter = formatterFor(chartData.meta.first_measure_labels_format);
    this.yAxisFormatter = formatterFor(chartData.meta.second_measure_labels_format);
  }

  setContent = (seriesIndex) => ({
    tooltipTitle: this.chartData.series[seriesIndex].name,
    tooltipXMeasureLabel: this.chartData.meta.first_measure_title_text,
    tooltipXMeasureValue: this.xAxisFormatter(this.chartData.series[seriesIndex].data[0][0]),
    tooltipYMeasureLabel: this.chartData.meta.second_measure_title_text,
    tooltipYMeasureValue: this.yAxisFormatter(this.chartData.series[seriesIndex].data[0][1])
  })

  render = ({ _series, seriesIndex, _dataPointIndex, _w }) => {
    const content = this.setContent(seriesIndex);

    return(`
      <div class="apexcharts-tooltip-title">${content.tooltipTitle}</div>
      <div class="apexcharts-tooltip-series-group apexcharts-active">
        <div class="apexcharts-tooltip-series-text">
            <div class="apexcharts-tooltip-text-label">${content.tooltipYMeasureLabel}</div>
            <div class="apexcharts-tooltip-text-value">${content.tooltipYMeasureValue}</div>
            <div class="apexcharts-tooltip-text-label">${content.tooltipXMeasureLabel}</div>
            <div class="apexcharts-tooltip-text-value">${content.tooltipXMeasureValue}</div>
        </div>
      </div>
    `)
  }
}

export default ScatterPlotTooltip;
