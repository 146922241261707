class PollStatusDetails {
  constructor (element) {
    this.$element = $(element)
    this.url = this.$element.data('scrape-status-url')
  }

  startPolling () {
    this.timer = setInterval(this._poll.bind(this), 3000)
    $(document).one('turbo:before-cache', this._clearTimer.bind(this))
  }

  _clearTimer () {
    clearInterval(this.timer)
  }

  _poll () {
    $.getJSON(this.url).done(({ data: {
      inputCount, percentCompleted, complete, wallClockTime, endpointWaitTime, avgResponseTime
    } }) => {
      if (complete) this._clearTimer()

      this.$element.find('[data-attr="input-count"]').text(inputCount)
      this.$element.find('[data-attr="percent-completed"]').html(percentCompleted)
      this.$element.find('[data-attr="wall-clock-time"]').html(wallClockTime)
      this.$element.find('[data-attr="endpoint-wait-time"]').html(endpointWaitTime)
      this.$element.find('[data-attr="avg-response-time"]').html(avgResponseTime)
    })
  }
}

document.addEventListener('turbo:load', () => {
  $('[data-complete=false][data-scrape-status-url]').each(function() {
    new PollStatusDetails(this).startPolling();
  });
})

export default PollStatusDetails;
