import { merge } from 'lodash';

class WithWidgetOptions {
  constructor(chartData, optionsBuilder) {
    this.chartData = chartData;
    this.optionsBuilder = optionsBuilder;

    this.widgetOptions = {
      chart: {
        height: '100%',
        width: '100%',
      },
      grid: {
        padding: {
          left: 35,
          right: 35,
          top: 20,
          bottom: 20
        }
      },
      legend: {
        position: 'top'
      },
      title: {
        offsetY: 10
      },
      xaxis: {
        title: {
          offsetY: 10
        }
      },
      yaxis: {
        labels: {
          offsetX: 25
        }
      }
    }

    this.builderWithWidgetOptions = new optionsBuilder(merge({}, this.widgetOptions, this.chartData))
  }

  build = () => this.builderWithWidgetOptions.build()
}

export default WithWidgetOptions;
