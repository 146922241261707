import { DomLike } from "./dom_like"
import { FILTER_TYPE_CHANGED, RESULT_FILTERS_ADDED, RESULT_FILTERS_REMOVED, WIDGET_FILTERS_UPDATED, WIDGET_FILTER_TYPE_SELECTED, WIDGET_FILTER_TYPE_UNSELECTED } from './events'
import { WidgetFilter } from "./widget_filter"

const FILTER_TYPE_RADIO_SELECTOR = '.filter-type-radio'
const WIDGET_FILTER_WRAPPER_SELECTOR = '.custom-brix-widget-filter-wrapper'
const RESULT_FILTER_LABEL_SELECTOR = '.result-filters-label'
const DESTROY_SELECTOR = '.destroy-result-filters'
const FILTER_TYPES = {
  INTERESTING_SLICE: 'interesting_slice',
  NONE: 'none',
  WIDGET_FILTER: 'widget_filter',
}

export class ResultFilter extends DomLike {
  constructor(resultFilter, formComponent) {
    super(resultFilter)

    this.$label = this.find(RESULT_FILTER_LABEL_SELECTOR)
    this.$destroyField = this.find(DESTROY_SELECTOR)

    this.filterType = this.currentfilterType()
    this.formComponent = formComponent
    this.widgetFilterComponents = []

    this.setupFiterType()
    this.setupWidgetFilters()
    this.setupCocoon()
    this.setupAdditionalFiltersEvents()
  }

  get form() {
    return this.formComponent
  }

  setupFiterType() {
    this.on('change', FILTER_TYPE_RADIO_SELECTOR, () => { this.pub(FILTER_TYPE_CHANGED) })
    this.sub(FILTER_TYPE_CHANGED, () => { this.onfilterTypeChanged() })
    this.pub(FILTER_TYPE_CHANGED)
  }

  setupWidgetFilters() {
    this.find(WIDGET_FILTER_WRAPPER_SELECTOR).each((_, wrapper) => { this.widgetFilterComponents.push(new WidgetFilter(wrapper, this)) })

    this.sub(WIDGET_FILTERS_UPDATED, () => { this.notifyCollection(this.widgetFilterComponents, WIDGET_FILTERS_UPDATED) })
  }

  setupAdditionalFiltersEvents() {
    this.sub(RESULT_FILTERS_ADDED, () => { this.onAdded() })
    this.sub(RESULT_FILTERS_REMOVED, () => { this.onRemoved() })
  }

  currentfilterType() {
    return this.find(`${FILTER_TYPE_RADIO_SELECTOR}:checked`).val()
  }

  // When one filter type is selected ("none", "widget_filter", or "interesting_slice")
  // enable the filters for the selected filter type and disable the rest
  onfilterTypeChanged() {
    this.filterType = this.currentfilterType()
    this.find('[data-filter-type]').each((_, el) => $(el).attr('disabled', $(el).data('filter-type') !== this.filterType))

    if (this.filterType === FILTER_TYPES.WIDGET_FILTER) {
      this.notifyCollection(this.widgetFilterComponents, WIDGET_FILTER_TYPE_SELECTED)
    } else {
      this.notifyCollection(this.widgetFilterComponents, WIDGET_FILTER_TYPE_UNSELECTED)
    }
  }

  // add/remove widget filter
  setupCocoon() {
    this.on('cocoon:after-insert', (_, $newItem) => {
      this.widgetFilterComponents.push(new WidgetFilter($newItem, this, true))
    });

    // prevent adding/removing a new filter if the current filter type is not widget_filter
    this.on('cocoon:before-insert', (e) => {
      if (this.filterType !== FILTER_TYPES.WIDGET_FILTER)
        e.preventDefault();
    });

    this.on('cocoon:before-remove', (e) => {
      if (this.filterType !== FILTER_TYPES.WIDGET_FILTER)
        e.preventDefault();
    });

    this.on('cocoon:after-remove', (_event, $removedItem) => {
      for (let i = 0; i < this.widgetFilterComponents.length; ++i) {
        if (this.widgetFilterComponents[i].data('selectId') === $removedItem.data('selectId')) {
          this.widgetFilterComponents.splice(i, 1)
          break
        }
      }
    })
  }

  onAdded() {
    this.$label.show()
    this.$destroyField.val('0')
  }

  onRemoved() {
    this.$label.hide()
    this.$destroyField.val('1')
  }
}
