class GridItemConfigurationPanel {
  constructor(gridItem) {
    this.gridItem = gridItem;
    this.$root = gridItem.$root.find(".portlet__icon.configuration");

    this.isOpen = false;

    this._setup();
  }

  _setup = () => {
    this._registerDismissConfigurationPanelClickHandler();
    this._registerConfigurationIconClickHandler();
  };

  _registerDismissConfigurationPanelClickHandler = () => {
    $(document).on("click", ".dismiss-portlet-configuration", (e) => {
      e.preventDefault();
      const closedPanelGridItemId = $(e.target).closest(".widget-configuration").data("portlet-id");

      if (closedPanelGridItemId == this.gridItem.id) {
        this.close();
      }
    });
  };

  _registerConfigurationIconClickHandler = () => this.$root.on("click", this._toggle);

  _toggle = () => (this.isOpen ? this.close() : this.open());

  open = () => {
    $.get(this.$root.data("configuration-url"), (html) => {
      this.$root
        .popover({
          placement: "left",
          container: "body",
          content: html,
          html: true,
        })
        .popover("show");
    });

    this.isOpen = true;
    $(document).trigger("popover:load");
  };

  close = () => {
    if (this.$root[0]) {
      bootstrap.Popover.getOrCreateInstance(this.$root[0]).dispose();
    }

    this.isOpen = false;
  };
}

export default GridItemConfigurationPanel;
