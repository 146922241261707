// Load store and state management classes
import Store        from './../store/store';
import DefaultState from './store/default_state';

// Load components
import Calculator          from './components/calculator';
import MeasuresSelector    from './components/measures_selector';
import DataSourceSelector  from './components/data_source_selector';
import TestTypeSelector    from './components/test_type_selector';
import SamplesData         from './components/samples_data';
import Results             from './components/results';
import DatesSelector       from './components/dates_selector';
import TrafficPerTreatment from './components/traffic_per_treatment';
import ExpectedLift        from './components/expected_lift';
import FiltersSelector     from './components/filters_selector';
import ImpactPercentage    from './components/impact_percentage';
import LineChart           from './components/line_chart';

$(document).on('turbo:load', () => {

  if ($('#experiment-length-calculator').length > 0) {
    const wrapper = $('#experiment-length-calculator-wrapper');

    if (wrapper.length > 0) {
      // Load initial state, create store and pass it to the components
      const initialState = (new DefaultState({ element: wrapper })).loadState();
      const store = new Store({
        initialState
      });

      store.commit('initializationMode', true);

      [
        Calculator,
        SamplesData,
        Results,
        TestTypeSelector,
        DataSourceSelector,
        DatesSelector,
        MeasuresSelector,
        TrafficPerTreatment,
        ExpectedLift,
        FiltersSelector,
        LineChart,
        ImpactPercentage
      ].map((klass) => {
        return new klass({ store: store });
      }).forEach((instance) => {
        instance.initialize();
      });

      store.commit('initializationMode', false);
    }
  }
});
