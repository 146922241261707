// To use:
// 1. Add class "read-more-container" to the container that will be resized
// 2. Optionally, you can and "data-read-more-height-id" if you want the final height for truncation to be a specific value from an element on the page
// 3. Add class "read-more-text" to the element that will contain the text, and "data-original-text" attribute with the full text to be truncated

const truncateFlag = ' ...more';

const truncateText = ($textDom, $textContainer, maxHeight, text) => {
  // Set the original text on the destination box to start calculations
  $textDom.textContent = text;
  $textDom.dataset.finalText = text;

  // Truncate only if the text is bigger than the space available
  if ($textContainer.getBoundingClientRect().height > maxHeight) {
    const words = text.split(" ");
    let currentText = "";
    let left = 0;
    let right = words.length;

    // binary search so we approximate faster
    while (left < right) {
      const middle = Math.floor((left + right) / 2);
      if (left === middle) {
        break;
      }
      const temp = words.slice(left, middle).join(' ')

      $textDom.innerText = [currentText, temp, truncateFlag].join(' ')
      const { height } = $textContainer.getBoundingClientRect();
      if (height > maxHeight) {
        right = middle;
      } else {
        currentText = [currentText, temp].join(' ');
        left = middle;
      }
    }

    $textDom.textContent = '';
    let link = `<a class="read-more-link" href="#">${truncateFlag}</a>`
    $textDom.dataset.finalText = [currentText, link].join(' ');
  }
}

const readMoreExecute = ((container) => {
  const textContainer =  container.querySelector(".read-more-text");
  if (textContainer) {
    textContainer.innerHTML = textContainer.dataset.finalText;
  }

  // Wire read more link
  $(container).on('click', '.read-more-link', (e) => {
    e.preventDefault();
    const text = e.currentTarget.closest('.read-more-text');
    text.innerHTML = $(text).data('original-text');
  });
});

const readMoreSetup = (container, targetHeightContainer) => {
  $(container).off('click');

  if (targetHeightContainer == null) {
    targetHeightContainer = container;
  }
  const finalHeight = targetHeightContainer.getBoundingClientRect().height;

  // Perform truncation on the element with the actual text
  const textContainer =  container.querySelector(".read-more-text");
  if (textContainer) {
    truncateText(textContainer, container, finalHeight, textContainer.dataset.originalText);
  }
}

const readMoreReset = (el) => {
  el.textContent = '';
  const container = el.closest('.read-more-container')
  const targetHeightElement = document.getElementById(container.dataset.readMoreHeightId);
  readMoreSetup(container, targetHeightElement);
  readMoreExecute(container);
}

$(window).on('resize', () => {
  $('.read-more-text').each((_i, el) => {
    readMoreReset(el)
  });
});

$(document).on("turbo:load", () => {

  // Calculate all truncations before executing them to avoid influencing other heights
  new Promise(function(resolve, reject) {
    $(".read-more-container").each((_i, el) => {
      const c = document.getElementById(el.dataset.readMoreHeightId);
      readMoreSetup(el, c);
    });
    resolve();
  }).then(() => {
    $(".read-more-container").each((_i, el) => {
      readMoreExecute(el);
    })
  });

});
