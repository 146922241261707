import { merge, omitBy, isNil } from 'lodash'

class TableOfContents {
  static templates = {
    nav: (items) => {
      if (!items.length) return ''

      return `<nav class="sticky-top">${items}</nav>`
    },
    item: (id, label, className) => {
      if (!label) return ''

      return `<a class="nav-link text-truncate ${className}" href="#${id}">${label}</a>`
    }
  }

  static defaultOptions = {
    depth: 6,
    scrollspy: true,
    scrollOffsetY: 80
  }

  constructor (element, target, options) {
    this.$element = $(element)
    this.$target = $(target)
    this.options = merge(
      {},
      this.constructor.defaultOptions,
      omitBy(options, isNil)
    )
  }

  create () {
    const headings = Array.from({ length: this.options.depth }, (_, i) => `h${i + 1}`)
    const html = $.map(this.$target.find(headings.join(', ')), (el, index) => {
      return this._renderItem(el, index)
    }).join('')

    this.$element.html(this.constructor.templates.nav(html))
    this._bindScrollspyEvent()
    this._bindItemClickEvent()

    return this
  }

  _bindScrollspyEvent () {
    if (!this.options.scrollspy) return

    const scrollspyBufferY = 1
    const spyElement = document.body

    const spy = new bootstrap.ScrollSpy(spyElement, {
      target: `#${this.$element.attr('id')}`,
      offset: this.options.scrollOffsetY + scrollspyBufferY
    })

    $(document).one('turbo:before-cache', () => spy.dispose())
  }

  _bindItemClickEvent () {
    this.$element.on('click', 'a', (e) => {
      const id = $(e.currentTarget).attr('href')
      const offsetTop = this.$target.find(id).offset().top - this.options.scrollOffsetY

      e.preventDefault()
      e.stopPropagation()

      $(document).scrollTop(offsetTop)
    })
  }

  _renderItem(el, index) {
    const label = $(el).text().trim()
    const id = `toc-${index}`
    const className = `nav-${el.localName}`

    $(el).attr('id', id)

    return this.constructor.templates.item(id, label, className)
  }
}

document.addEventListener('turbo:load', () => {
  const $contentList = $('[data-generate="toc"]')

  $.map($contentList, (el) => {
    const target = $(el).data('toc-for')
    const options = {
      depth: $(el).data('toc-depth'),
      scrollspy: $(el).data('toc-scrollspy')
    }

    return (new TableOfContents(el, target, options)).create()
  })
})

export default TableOfContents
