$(document).on('turbo:load', () => {
  const body = $('body');
  const agent = navigator.userAgent;

  if (agent.indexOf('Safari') !== -1 && agent.indexOf('Chrome') === -1) {
    body.addClass('browser-context--safari')
  } else if (agent.indexOf('Chrome') !== -1) {
    body.addClass('browser-context--chrome')
  } else if (agent.indexOf('Firefox') !== -1) {
    body.addClass('browser-context--firefox')
  }
});
