$(document).on('turbo:load', function () {
  const el = $('#plan-experiment-form');

  if (el.length) {
    setTreatments();

    $('#number-of-treatments').on('change', function(e) {
      let adjustedValue = parseInt($(e.target).val());

      if (adjustedValue < 2) {
        adjustedValue = 2;
      }
      if (adjustedValue > 100) {
        adjustedValue = 100;
      }

      $(e.target).val(adjustedValue);
    });
  }

  $(document).on('ajax:complete', '#interesting-slice', function(e) {
    const [{ responseText }] = e.detail;

    e.stopPropagation();
    $(e.currentTarget).html(responseText);
  });

  $(document).on('ajax:complete', '#spike-check', function(e) {
    const [{ responseText }] = e.detail;

    e.stopPropagation();
    $(e.currentTarget).html(responseText);
  });
});

setTreatments = () => {
  // Imported and adapted from experiments treatments edit
  const el = $('#treatment-fields');

  const updateNamePlaceholder = (idx, element) => {
    if ($(this).find('input[name$="[id]"]').length > 0) { return; }

    const nameInput = $(element).find('input[name$="[name]"]');

    let defaultName = '';
    if (idx == 0) {
      defaultName = 'Control';
    } else {
      defaultName = `Treatment ${String(idx).padStart(2, '0')}`;
    }

    nameInput.attr('placeholder', defaultName);
  };

  const updatePercentOfTraffic = (idx, element) => {
    if ($(this).find('input[name$="[id]"]').length > 0) { return; }

    // If we re-sized the experiment we want all new treatments with the same % of traffic
    const generalTrafficValue = $('input[name$="[traffic_per_treatment]"]').val();

    if (generalTrafficValue != undefined && generalTrafficValue != '') {
      const trafficInput = $(element).find('input[name$="[percent_of_traffic]"]');
      trafficInput.val(generalTrafficValue);
    }
  };

  const eachTreatmentRow = (callback) => {
    el.find('div.treatment-fields:visible').each((idx, el) => {
      callback?.call(this, idx, el);
    });
  };

  el.on('cocoon:after-insert', () => {
    eachTreatmentRow(updateNamePlaceholder);
    eachTreatmentRow(updatePercentOfTraffic);
  });

  el.on('cocoon:after-remove', () => {
    eachTreatmentRow(updateNamePlaceholder);
    eachTreatmentRow(updatePercentOfTraffic);
  });

  eachTreatmentRow(updateNamePlaceholder);
  eachTreatmentRow(updatePercentOfTraffic);
};
