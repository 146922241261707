import { orderBy, isNil } from 'lodash';
import formatterFor from '../formatter_for';

class LineChartTooltip {
  constructor(normalizer, metadata) {
    this.normalizer = normalizer
    this.valueFormatter = formatterFor(metadata.format)
    this.titleFormatter = formatterFor(metadata.granularity)
    this.colors = metadata.colors;

    this.presortedSeriesContent = this._presortedSeriesContent()
  }

  render = ({ dataPointIndex, ctx }) => {
    const content = this._setContent(dataPointIndex, ctx);

    return (
      `<div class="apexcharts-tooltip-title">${content.title}</div>` +
      content.seriesItems.map((s) => `
        <div class="apexcharts-tooltip-series-group apexcharts-active ${s.seriesGroupDisplayClass}">
          <span class="apexcharts-tooltip-marker" style="background-color: ${s.color}"></span>
          <div class="apexcharts-tooltip-text">
            <div class="apexcharts-tooltip-y-group">
              <span class="apexcharts-tooltip-text-label">${s.seriesName}</span>
              <span class="apexcharts-tooltip-text-value"><strong>${s.formattedValue}</strong></span>
            </div>
          </div>
        </div>
      `).join('')
    )
  }

  _setContent = (i, ctx) => ({
    title: this.presortedSeriesContent[i][0].timestamp,
    seriesItems: this._withoutHiddenSeries(this.presortedSeriesContent[i], ctx)
  })

  _withoutHiddenSeries = (series, ctx) =>
    series.filter((s) => !ctx.series.isSeriesHidden(s.seriesName).isHidden)

  _presortedSeriesContent = () =>
    this.presortedSeriesContent ||
    this._transpose(this._seriesWithTooltipContent()).map((content) =>
      orderBy(content, ({ rawValue }) => isNil(rawValue) ? -Infinity : rawValue, ['desc']))

  _transpose = (array) => array[0].map((_, i) => array.map(row => row[i]))

  _seriesWithTooltipContent = () => {
    return this.normalizer.timeSortedSeries.map((series) => series.data).map(
      (values, i) => values.map((value) => ({
        color: this.colors[i % this.colors.length],
        seriesName: this.normalizer.dimensionNames[i],
        formattedValue: this.valueFormatter(value.y),
        rawValue: value.y,
        timestamp: this.titleFormatter(value.x),
        seriesGroupDisplayClass: (isNil(value.y) ? 'apexcharts-series-group--null-value' : '')
      }))
    )
  }
}

export default LineChartTooltip;
