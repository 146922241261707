function checkComparisonProgress() {
  const $el = $('.comparison-progress');

  if ($el.length) {
    $.getJSON($el.data('url'), (comparison) => {
      if (comparison[$el.data('completeAttribute')]) {
        Turbo.visit(location.toString());
      } else {
        setTimeout(checkComparisonProgress, 1000);
      }
    });
  }
}

$(document).on("turbo:load", checkComparisonProgress);
