const cleanStartAndEndTimeStamps = (data) => {
  data["starts_at"] = new Date(data["starts_at"]);
  data["ends_at"] = new Date(data["ends_at"]);

  if (data["experiment_starts_at"]) {
    data["experiment_starts_at"] = new Date(data["experiment_starts_at"]);
  }

  if (data["experiment_ends_at"]) {
    data["experiment_ends_at"] = new Date(data["experiment_ends_at"]);
  }

  if (data["previous_status_periods"]) {
    data["previous_status_periods"].map(cleanStartAndEndTimeStamps);
  }

  return data;
};

export const clean = (data) => {
  data["experiments"].map(cleanStartAndEndTimeStamps);
  return data;
}

export const areIdenticalDateTimes = (dateTime, anotherDateTime) =>
  dateTime.getTime() === anotherDateTime.getTime()

export const isPast = (dateTime) => dateTime.getTime() <= new Date().getTime()
