import Component from '../../store/lib/component.js';

export const TEST_TYPE_SELECTOR = '#elc-test-type';
export const URL_PARAMETER = 'test-type';

export default class TestTypeSelector extends Component {
  constructor(props = {}) {
    super({
      ...props,
      urlParameter: URL_PARAMETER
    });

    this.$testTypeSelect = $(TEST_TYPE_SELECTOR);

    this.$testTypeSelect.on('change', () => {
      this.changeTestType(this.$testTypeSelect.val());
      props.store.events.publish('fetchSamplesData');
      this.dataStorage.addOrModify(this.urlParameter, this.$testTypeSelect.val());
    });

    const val = this.dataStorage.get(this.urlParameter);
    if (val != null) {
      this.changeTestType(val);
    }
  }

  initialize() {
    const val = this.store.state.testType;

    if (val != null) {
      this.store.commit('testType', val );
      this.store.commit('zAlpha', parseFloat(val));

      this.$testTypeSelect.val(val).trigger('change');
    }
  }

  changeTestType(val) {
    this.store.commit('testType', val);
    this.store.commit('zAlpha', parseFloat(val));
  }
}
