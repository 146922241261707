import { select, scaleTime } from 'd3';
import * as d3 from 'd3';

import EntryList from './components/entry_list';
import DateBar from './components/date_bar';
import Axis from './components/axis';
import ScrollManager from './utils/scroll_manager';

class Timeline {
  DEFAULT_CHART_DATA = { experiments: [], date_bar: [], meta: {} }

  DEFAULT_LAYOUT = {
    minChartWidth: 1600,
    chartScale: 1.5,
    startDate: -14,
    endDate: 60,
    chartLeftMarginOffset: 20
  }

  constructor({ rootObject, data }) {
    this.root = select(rootObject)
    this.data = Object.assign({}, this.DEFAULT_CHART_DATA, data);
    this.layout = Object.assign({}, this.DEFAULT_LAYOUT);
    this.startsAt = new Date(this.data.meta.starts_at);
    this.endsAt = new Date(this.data.meta.ends_at);

    this.rootWidth = this.rootWidth();
    this.chartWidth = this.chartWidth();
    this.scale = this.timeScale();

    this.axis = this.axis();
    this.dateBar = this.dateBar();
    this.entries = this.entries();
    this.scrollManager = this.scrollManager();
  }

  hasRequiredChartData = () => this.data.experiments.length && this.data.date_bar.length

  scrollManager = () =>
    new ScrollManager({
      root: this.root,
      scale: this.scale,
      layout: this.layout,
      rootWidth: this.rootWidth,
      entries: this.entries,
      startsAt: this.startsAt
    })

  timeScale = () =>
    scaleTime()
    .domain([this.startsAt, this.endsAt])
    .range([0, this.chartWidth])

  rootWidth = () => parseFloat(this.root.style("width"))
  chartWidth = () => Math.max(this.rootWidth * this.layout.chartScale, this.layout.minChartWidth)

  entries = () =>
    new EntryList({
      root: this.root,
      scale: this.scale,
      rootWidth: this.rootWidth,
      experimentData: this.data.experiments,
      layout: this.layout
    })

  axis = () => new Axis({ root: this.root, scale: this.scale, chartWidth: this.chartWidth, layout: this.layout })

  dateBar = () =>
    new DateBar({
      root: this.root,
      scale: this.scale,
      initialData: this.data.date_bar,
      layout: this.layout
    })

  drawEmpty = () =>
    this.root
      .append("div")
      .attr("id", "timeline__empty-chart")
      .text(() => this.emptyTimelineText())

  emptyTimelineText = () => {
    const format = "%m/%d/%y";
    const formattedStartsAt = d3.utcFormat(format)(this.startsAt);
    const formattedEndsAt = d3.utcFormat(format)(this.endsAt);

    return `There are no experiments ending after ${formattedStartsAt} or starting before ${formattedEndsAt}.`
  }

  draw = () => {
    this.root.classed('invisible', true);

    this.root.selectAll("*").remove();

    if (this.hasRequiredChartData()) {
      this.entries.draw();
      this.axis.draw();
      this.dateBar.draw(this.startsAt, this.endsAt);
      this.scrollManager.setScrollBehavior();
    } else {
      this.drawEmpty();
    }

    window.requestAnimationFrame(() => {
      this.root.classed('invisible', false);
    });
  }
}

export default Timeline;
