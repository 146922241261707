export class DomLike {
  constructor(element) {
    this.$element = $(element)
  }

  on(...args) {
    return this.$element.on(...args)
  }

  find(...args) {
    return this.$element.find(...args)
  }

  pub(...args) {
    return $(this).trigger(...args)
  }

  sub(...args) {
    return $(this).on(...args)
  }

  data(...args) {
    return this.$element.data(...args)
  }

  notifyCollection(collection, event, data) {
    for (const element of collection) {
      element.pub(event, data)
    }
  }
}
