import Rails from "@rails/ujs";

document.addEventListener('turbo:before-cache', () => {
  // from https://www.honeybadger.io/blog/turbolinks/:
  // Manually tear down bootstrap modals before caching. If turbo
  // caches the modal then tries to restore it, it breaks bootstrap's JS.
  // We can't just use bootstrap's `modal('close')` method because it is async.
  // Turbo will cache the page before it finishes running.
  if ($(document.body).hasClass('modal-open')) {
    $('.modal')
      .hide()
      .removeAttr('aria-modal')
      .attr('aria-hidden', 'true');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }
});

$(document).on('click', '.modal-link', function(e) {
  e.preventDefault();

  const $target = $(this);
  const requestedURL = $target.attr('href');
  const requestedModalSize = $target.data('modalSize');

  $('#modal-container .modal-content').load(requestedURL, function() {
    const dialogClasses = requestedModalSize ? `modal-dialog modal-${requestedModalSize}` : 'modal-dialog';

    $('#modal-container .modal-dialog').attr('class', dialogClasses);
    $('#modal-container').modal('show');
    $target.trigger('modal:load', { modal: $('#modal-container')[0] });
  })
})

$(document).on('click', '#modal-container button[type="submit"]', function(e) {
  e.preventDefault();

  const searchForm = e.target.closest('.modal-content').querySelector('form');
  const isRemoteForm = searchForm.getAttribute('data-remote');
  const $submitButton = $(this);
  const disableWithText = $submitButton.data('disable-with');

  if (disableWithText) {
    const originalButtonText = $submitButton.text();

    $submitButton.prop('disabled', true);
    $submitButton.text(disableWithText);

    $(searchForm).one('ajax:complete turbo:submit-end', () => {
      $submitButton.prop('disabled', false);
      $submitButton.text(originalButtonText);
    })
  }

  isRemoteForm ? Rails.fire(searchForm, 'submit') : searchForm.submit();
})
