import Component from '../../store/lib/component.js';
import LineChartDashboardTemplateOptions from "apex/utils/chart_options/line_chart_dashboard_template_options";
import WithWidgetOptions from 'apex/utils/chart_options/extenders/with_widget_options';
import ApexCharts from "apexcharts";

export const LINE_CHART_CONTAINER = '#elc-line-chart-container';
export const LINE_CHART_MESSAGE   = '#elc-line-chart-message';
export const LINE_CHART_SELECTOR  = 'elc-line-chart';

export default class LineChart extends Component {
  constructor(props = {}) {
    super(props);

    this.lineChart = document.getElementById(LINE_CHART_SELECTOR);
    this.$lineChart = $(`#${LINE_CHART_SELECTOR}`);
    this.$lineChartContainer = $(LINE_CHART_CONTAINER);
    this.$lineChartMessage = $(LINE_CHART_MESSAGE);
    this.$lineChartMessage.hide();
    this.chart = null;

    props.store.events.subscribe('renderChart', () => {
      this.$lineChart.show();
      this.renderChart();
    });

    props.store.events.subscribe('destroyChart', () => this.destroyChart());
    this.store.events.subscribe('showNoDataMessage', () => this.noDataMessage());
  }

  destroyChart = () => {
    this.chart && this.chart.destroy();
    this.chart = null;
    this.$lineChart.hide();
    this.$lineChart.empty();
    this.$lineChart.removeClass('border');
  }

  renderChart() {
    this.destroyChart();
    this.$lineChartMessage.hide()

    let data = this.store.state.chartData;

    if (Object.keys(data.lines)?.length) {
      this.$lineChart.show();
      const options = new WithWidgetOptions(data, LineChartDashboardTemplateOptions).build();
      this.chart = new ApexCharts(this.lineChart, options);
      this.chart.render();
      this.$lineChart.addClass('border');
      $(document).one("turbo:before-cache", () => this.chart.destroy());
    } else {
      const message = data.meta?.error_message || 'No data available';

      this.$lineChart.empty().append(`
        <div class="d-flex align-items-center justify-content-center p-5">
          <p class="lead">${message}</p>
        </div>
      `);
    }
  }

  noDataMessage() {
    this.store.events.publish('destroyChart');
    this.$lineChartMessage.html('There is no data available for the selected date range.');
    this.$lineChartMessage.show();
  }

}
