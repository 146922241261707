import { formattedDateTime } from "../utils/display";
import { areIdenticalDateTimes } from "../utils/data";

class PreviousStatusIndicators {
  constructor(options) {
    this.entries = options.entries;
    this.scale = options.scale;
    this.indicators = undefined;
  }

  writeDataToIndicators = () =>
    this.entries.append("div")
      .selectAll("div")
      .data((d) => d.previous_status_periods)
      .enter()
      .append("div")

  setIndicatorClasses = () =>
    this.indicators.attr("class", (d) => this.indicatorClasses(d))

  indicatorClasses = (d) => {
    const classes = ["timeline__entry-previous-status-period"];

    if (areIdenticalDateTimes(d["starts_at"], d["experiment_starts_at"])) {
      classes.push("timeline__entry-previous-status-period--coinciding-with-starts-at")
    }

    if (areIdenticalDateTimes(d["ends_at"], d["experiment_ends_at"])) {
      classes.push("timeline__entry-previous-status-period--coinciding-with-ends-at")
    }

    return classes.join(" ");
  }

  addIndicatorTooltips = () =>
    this.indicators
      .attr("data-bs-toggle", "tooltip")
      .attr("title", (d) => this.indicatorTooltipText(d))

  indicatorTooltipText = (d) => {
    const statusTypeText = `Experiment was ${d["status"]}`;

    let statusIntervalText = "";
    if (areIdenticalDateTimes(d["ends_at"], d["experiment_ends_at"])) {
      statusIntervalText = `on ${formattedDateTime(d["starts_at"])}.`;
    } else {
      statusIntervalText = `from ${formattedDateTime(d["starts_at"])} to ${formattedDateTime(d["ends_at"])}.`;
    }

    return `${statusTypeText} ${statusIntervalText}`;
  }

  setIndicatorWidths = () =>
    this.indicators.style("width", (d) => `${this.indicatorWidth(d)}px`)

  indicatorWidth = (d) =>
    this.scale(d["ends_at"]) - this.scale(d["starts_at"])

  setIndicatorPositions = () =>
    this.indicators.style("left", (d) => `${this.indicatorPositionOffset(d)}px`)

  indicatorPositionOffset = (d) =>
    this.scale(d["starts_at"]) - this.scale(d["experiment_starts_at"])

  draw = () => {
    this.indicators = this.writeDataToIndicators();
    this.setIndicatorClasses();
    this.addIndicatorTooltips();
    this.setIndicatorWidths();
    this.setIndicatorPositions();
  }
}

export default PreviousStatusIndicators;
