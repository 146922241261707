import ApexCharts from "apexcharts";

import BaseWidget from './base_widget';
import ScatterPlotOptions from '../../apex/utils/chart_options/scatter_plot_options';
import WithWidgetOptions from '../../apex/utils/chart_options/extenders/with_widget_options';

class ScatterPlotWidget extends BaseWidget {
  constructor(attributes) {
    super(attributes);

    this.chart = null;
    this.chartOptions = null;
  }

  load = () => $.getJSON(this.attributes.dataUrl, this.updateChartData).fail(this.handleLoadFailure)

  handleLoadFailure = (xhr) => this.renderDataErrorMessage(xhr.responseJSON?.message || 'No data available')

  destroyChart = () => {
    this.chart && this.chart.destroy();
    this.chart = null;
    this.element.empty();
  }

  buildChart = (data) => {
    this.chartOptions = new WithWidgetOptions(data, ScatterPlotOptions).build();
    this.chart = new ApexCharts(this.element.get(0), this.chartOptions);
  }

  render = () => this.chart && this.chart.render()

  updateChartData = (data) => {
    this.destroyChart();

    if (data.series.length) {
      this.buildChart(data);
      this.render();
    } else {
      this.renderDataErrorMessage(data.meta.error_message);
    }
  }
}

export default ScatterPlotWidget;
