import { Controller } from '@hotwired/stimulus'
import fuzzyMatch from '../../utils/fuzzy_match'
import { sortBy, merge } from 'lodash'

function matchStartByOptions(params, data) {
  if (params.term && data.text) {
    let matches, score, _;
    [matches, score, _] = fuzzyMatch(data.text, params.term);

    if (matches){
      data.score = score;
      return data;
    } else {
      return null;
    }
  } else {
    return data;
  }
}

// enables searching in select2 field with <optgroups>
// https://select2.org/searching#matching-grouped-options
function matchStartByGroupedOptions(params, data) {
  // If there are no search terms, return all of the data
  const term = (params.term || '').trim()
  if( term === '') {
    return data;
  }

  // Skip if there is no 'children' property
  if (typeof data.children === 'undefined') {
    return null;
  }

  // `data.children` contains the actual options that we are matching against
  let filteredChildren = [];
  $.each(data.children, function (idx, child) {
    let matches, score, _;
    [matches, score, _] = fuzzyMatch(child.text, term);
    if (matches){
      child.score = score;
      filteredChildren.push(child);
    }
  });

  // If we matched any of the  group's children, then set the matched children on the group
  // and return the group object
  if (filteredChildren.length) {
    let modifiedData = $.extend({}, data, true);
    modifiedData.children = filteredChildren;

    // You can return modified objects from here
    // This includes matching the `children` how you want in nested data sets
    return modifiedData;
  }

  // Return `null` if the term should not be displayed
  return null;
}

export default class extends Controller {
  static stateTemplate = (state, dataAttribute) => {
    const template = $($(state.element).data(dataAttribute))

    return state.id && template.length ? template : state.text
  }

  static defaultOptions = {
    theme: 'bootstrap',
    width: '100%',
    sorter: (data) => data.sort((a, b) => b.score - a.score),
    templateResult: (state) => this.stateTemplate(state, 'template-result'),
    templateSelection: (state) => this.stateTemplate(state, 'template-selection')
  }

  static values = { 
    options: Object,
    groupedOptions: { type: Boolean, default: false }
  }

  connect() {
    $(this.element)
      .select2(this.#options()).on('select2:select select2:unselect', function (event) {
        event.target.dispatchEvent(new Event('change'))
      })
  }

  disconnect() {
    $(this.element).select2('destroy')
  }

  #options() {
    let options = {
      ...this.constructor.defaultOptions,
      ...this.optionsValue,
      dropdownParent: this.#getElementDropdownParent(),
      matcher: this.groupedOptionsValue ? matchStartByGroupedOptions : matchStartByOptions
    }

    if (options?.ajax) {
      options = merge({}, {
        placeholder: '',
        allowClear: true,
        ajax: {
          delay: 250,
          dataType: 'json',
          processResults: function (data, {term} ) {
            const results = sortBy(data.results, function(el) { return fuzzyMatch(el.text, term || "")[1] * -1 })

            return { results }
          }
        }
      }, options)
    }

    return options
  }

  #getElementDropdownParent() {
    const dropdownParent = this.element.closest(this.optionsValue.dropdownParent) || document.body
    const form = this.element.closest('form')

    return dropdownParent.contains(form) ? form : dropdownParent
  }
}
