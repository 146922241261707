import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ab--plan-experiment-treatment"
export default class extends Controller {
  static targets = [ "pegasusUiAction", "pegasusUiForm", "destroyField" ]
  static values = {
    showUi: Boolean
  }

  connect() {
    if (this.showUiValue) {
      this.showPegasusUi();
    }
  }

  showPegasusUi() {
    this.destroyFieldTarget.value = false;
    this.pegasusUiFormTarget.classList.remove('d-none');
    this.pegasusUiActionTarget.classList.add('d-none');
  }

  hidePegasusUi() {
    this.destroyFieldTarget.value = true;
    this.pegasusUiFormTarget.classList.add('d-none');
    this.pegasusUiActionTarget.classList.remove('d-none');
  }
}
