import { sortBy } from 'lodash';
import { minMax } from 'utils/array';

class LineChartDataNormalizer {
  constructor(chartData) {
    this.series = chartData.lines
    this.customYMax = chartData.meta.y_max
    this.customYMin = chartData.meta.y_min

    this.seriesWithDataSortedByXValues = this._seriesWithDataSortedByXValues()
    this.xValues = this._xValues()

    this.yValuesWithCustomMaxAndMin = this._yValuesWithCustomMaxAndMin()
    this.yMaxAndMin = this._yMaxAndMin()
  }

  get dimensionNames() { return Object.keys(this.series) }
  get timeSortedSeries() { return this.seriesWithDataSortedByXValues }
  get timestamps() { return this.xValues }
  get yRange() { return this.yMaxAndMin }

  _seriesWithDataSortedByXValues = () =>
    this.seriesWithDataSortedByXValues ||
    Object.entries(this.series).map(([name, data]) => ({ name, data: sortBy(data, ['x']) }))

  _xValues = () =>
    this.xValues ||
    this.seriesWithDataSortedByXValues[0]?.data.map(d => d.x) ||
    []

  _yMaxAndMin = () => {
    const [min, max] = minMax(this.yValuesWithCustomMaxAndMin)

    return { min, max }
  }

  _yValuesWithCustomMaxAndMin = () =>
    this.yValuesWithCustomMaxAndMin ||
    this._withoutNulls(this._yValues().concat([this.customYMin, this.customYMax]))

  _withoutNulls = (valuesArr) => valuesArr.filter((value) => value != null)

  _yValues = () =>
    this.seriesWithDataSortedByXValues.map((series) => series.data).map((data) => data.map((d) => d.y)).flat()
}

export default LineChartDataNormalizer;
