import Component from '../../store/lib/component.js';

export const DATES_SELECTOR                = '#elc-dates-selector';
export const DATES_SELECTOR_START          = '#elc-dates-selector-start';
export const DATES_SELECTOR_END            = '#elc-dates-selector-end';
export const DATES_SELECTOR_CUSTOM_WRAPPER = '#elc-dates-selector-custom';

export const DATES_URL_PARAMETER           = 'date-range';
export const DATES_URL_PARAMETER_STARTS_AT = 'date-range-starts-at';
export const DATES_URL_PARAMETER_ENDS_AT   = 'date-range-ends-at';

export default class DatesSelector extends Component {
  constructor(props = {}) {
    super({
      ...props,
      urlParameter: DATES_URL_PARAMETER,
    });

    $(DATES_SELECTOR).on('change', (e) => {
      const value = $(e.target).val();
      props.store.commit('dateType', value);

      if (value == '') {
        $(DATES_SELECTOR_CUSTOM_WRAPPER).removeClass('elc-hide');
      } else {
        this.setStartDate(null);
        this.setEndDate(null);
        this.dataStorage.delete(DATES_URL_PARAMETER_STARTS_AT);
        this.dataStorage.delete(DATES_URL_PARAMETER_ENDS_AT);
        $(DATES_SELECTOR_CUSTOM_WRAPPER).addClass('elc-hide');
      }

      props.store.events.publish('fetchSamplesData');
      this.dataStorage.addOrModify(this.urlParameter, $(e.target).val());
    });


    $('.datetime-picker-input').on('change.td', () => {
      if ($(DATES_SELECTOR).val() == '') {
        this.setStartDate($(DATES_SELECTOR_START).find('input').val());
        this.setEndDate($(DATES_SELECTOR_END).find('input').val());

        props.store.events.publish('fetchSamplesData');
      }
    });

    const val = this.dataStorage.get(this.urlParameter);
    if (val != null) {
      this.store.commit('dateType', val);

      if (val == '') {
        const startsAt = this.dataStorage.get(DATES_URL_PARAMETER_STARTS_AT);
        this.store.commit('dateStart', (!startsAt || startsAt.length === 0 ) ? null : startsAt);

        const endsAt = this.dataStorage.get(DATES_URL_PARAMETER_ENDS_AT);
        this.store.commit('dateEnd', (!endsAt || endsAt.length === 0 ) ? null : endsAt);
      } else {
        this.setStartDate(null);
        this.setEndDate(null);
      }
    }
  }


  initialize() {
    const val = this.store.state.dateType;

    if (val != null) {
      $(DATES_SELECTOR).val(val);
      this.store.commit('dateType', val);

      if (val == '') {
        this.setStartDate(this.store.state.dateStart);
        this.setEndDate(this.store.state.dateEnd);

        $(DATES_SELECTOR_CUSTOM_WRAPPER).removeClass('elc-hide');
      } else {
        this.setStartDate(null);
        this.setEndDate(null);
        this.dataStorage.delete(DATES_URL_PARAMETER_STARTS_AT);
        this.dataStorage.delete(DATES_URL_PARAMETER_ENDS_AT);
        $(DATES_SELECTOR_CUSTOM_WRAPPER).addClass('elc-hide');
      }

      $(DATES_SELECTOR).trigger('change');
    }
  }

  setStartDate(startsAt) {
    this.store.commit('dateStart', (!startsAt || startsAt.length === 0 ) ? null : startsAt);
    $(DATES_SELECTOR_START).find('input').val(startsAt);
    this.dataStorage.addOrModify(DATES_URL_PARAMETER_STARTS_AT, startsAt);
  }

  setEndDate(endsAt) {
    this.store.commit('dateEnd', (!endsAt || endsAt.length === 0 ) ? null : endsAt);
    $(DATES_SELECTOR_END).find('input').val(endsAt);
    this.dataStorage.addOrModify(DATES_URL_PARAMETER_ENDS_AT, endsAt);
  }

}
