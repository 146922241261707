class CharacterCounter {
  constructor(element) {
    this.$element = $(element)
    this.$characterCount = this.$element.find('[data-character-counter-target="character-count"]')
    this.$textarea = this.$element.find('textarea').first()
  }

  create() {
    this.#count()
    this.$textarea.on('input', this.#count.bind(this))

    return this
  }

  #count() {
    this.$characterCount.text(this.$textarea.val().length)
  }
}

const initCharacterCounter = (element) => {
  $.map($(element).find('[data-character-counter]'), (el) => (new CharacterCounter(el)).create())
}

$(document).on('turbo:load', () => initCharacterCounter(document))
$(document).on('task:load', (e) => initCharacterCounter(e.target))

export default CharacterCounter
