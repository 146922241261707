// Load store and state management classes
import Store        from '../../../utils/store/store';
import DefaultState from '../../../utils/experiment_length_calculator/store/default_state';

// Load components
import PlanExperimentDataStorage from './components/plan_experiment_data_storage'
import SubmitSizing        from './components/submit_sizing'
import DatesRefresh        from './components/dates_refresh'
import Sequential          from './components/sequential'
import Calculator          from '../../../utils/experiment_length_calculator/components/calculator';
import MeasuresSelector    from '../../../utils/experiment_length_calculator/components/measures_selector';
import DataSourceSelector  from '../../../utils/experiment_length_calculator/components/data_source_selector';
import TestTypeSelector    from '../../../utils/experiment_length_calculator/components/test_type_selector';
import SamplesData         from '../../../utils/experiment_length_calculator/components/samples_data';
import Results             from '../../../utils/experiment_length_calculator/components/results';
import DatesSelector       from '../../../utils/experiment_length_calculator/components/dates_selector';
import TrafficPerTreatment from '../../../utils/experiment_length_calculator/components/traffic_per_treatment';
import ExpectedLift        from '../../../utils/experiment_length_calculator/components/expected_lift';
import FiltersSelector     from '../../../utils/experiment_length_calculator/components/filters_selector';
import ImpactPercentage    from '../../../utils/experiment_length_calculator/components/impact_percentage';
import LineChart           from '../../../utils/experiment_length_calculator/components/line_chart';

const MAP_FORM_FIELDS_TO_STORAGE = {
  'brix_data_source_id': 'data-source',
  'period_type': 'date-range',
  'date_start': 'date-range-starts-at',
  'date_end': 'date-range-ends-at',
  'measure_id': 'measure',
  'test_type': 'test-type',
  'expected_lift': 'expected-lift',
  'traffic_per_treatment': 'traffic-per-treatment',
  'impact_percentage': 'impact-percentage',
  'filters': 'filters',
  'sequential': 'sequential'
};

$(document).on('turbo:load', () => {
  if ($('#plans-experiment-sizing-section').length) {
    setSizing();
  }
});

$(document).on('turbo:after-stream-render', () => {
  // Initialization through turbo streams only happens when editing an existing experiment
  const el = $('#plans-experiment-sizing-section');

  if (el.length && !el.data('new-experiment')) {
    setSizing();
  }
});

function setSizing() {
  const wrapper = $('#plans-experiment-sizing-section');
  const button = $('#plan-experiment-size-button');

  initializeCalculator();

  button.on('click', (e) => {
    e.preventDefault();

    if (button.data('display-status') === 'hidden') {
      wrapper.show('fast');
      button.text('Close Sizing');
      button.data('display-status', 'open');

    } else {
      wrapper.hide('fast');
      button.text('Open Sizing');
      button.data('display-status', 'hidden');
    }
  });
};

function initializeCalculator() {
  if ($('#experiment-length-calculator-plan').length > 0) {
    const wrapper = $('#experiment-length-calculator-wrapper');
    wrapper.each(() => {
      // Load initial state, create store and pass it to the components
      const initialState = (new DefaultState({ element: wrapper })).loadState();
      const dataStorage = new PlanExperimentDataStorage();

      // Initialize dataStorage values before general calculator init
      // This will be the equivalent as having those values at the URL for the
      // regular calculator. Needed to trigger the calculation automatically
      for (const [formFieldName, storageKey] of Object.entries(MAP_FORM_FIELDS_TO_STORAGE)) {
        let value = $(`input[name="experiment[experiment_sizing_attributes][${formFieldName}]`).val();

        if (value !== '' && value !== null && value !== undefined) {
          dataStorage.addOrModify(storageKey, value);
        }
      }

      // Date range should be set empty '' when custom dates are selected
      // This is how date filterable works
      if (dataStorage.get('date-range-starts-at') && dataStorage.get('date-range-ends-at')) {
        dataStorage.addOrModify('date-range', '');
      }

      const store = new Store({
        initialState
      });

      // If we change the number of treatments trigger dates
      $('#number-of-treatments').on('change', () => {
        if ($('#experiment_experiment_sizing_attributes_calculated_days').val()) {
          store.events.publish('datesRefresh');
        } else {
          store.events.publish('submitSizing');
        }
      });

      store.commit('initializationMode', true);

      [
        SubmitSizing,
        Calculator,
        SamplesData,
        Results,
        TestTypeSelector,
        DataSourceSelector,
        DatesSelector,
        MeasuresSelector,
        TrafficPerTreatment,
        ExpectedLift,
        FiltersSelector,
        LineChart,
        ImpactPercentage,
        DatesRefresh,
        Sequential,
      ].map((klass) => {
        return new klass({ store: store, dataStorage: dataStorage });
      }).forEach((instance) => {
        instance.initialize();
      });

      store.commit('initializationMode', false);
    });
  }
}
