import Rails from "@rails/ujs";

$(() => {
  $(document).on('submit', 'form[class$=_search]', function() {
    $(this).find('tbody input').attr('disabled', 'disabled')
  });

  $(document).on('change', '.table-searchable thead select:not([multiple])', function(e) {
    const searchForm = e.target.closest('form');
    const isRemoteForm = searchForm.getAttribute('data-remote');

    isRemoteForm ? Rails.fire(searchForm, 'submit') : searchForm.submit();
  });
})

$(document).on('turbo:load', () => {
  $('[data-range-filter=true]').daterangepicker({
    autoUpdateInput: false,
    alwaysShowCalendars: true,
    ranges: {
      'Today':        [moment(), moment()],
      'Yesterday':    [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days':  [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month':   [moment().startOf('month'), moment().endOf('month')],
      'Last Month':   [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  });

  $('[data-range-filter=true]').on('apply.daterangepicker', function(e, picker) {
    $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));

    const searchForm = e.target.closest('form');
    const isRemoteForm = searchForm.getAttribute('data-remote');

    isRemoteForm ? Rails.fire(searchForm, 'submit') : searchForm.submit();
  })

  $(function() {
    $(document).find('.search-headers .search-field').tooltip({
      title: 'Press Return to search',
      delay: {
        show: 8000,
        hide: 100
      }
    })
  });
})
