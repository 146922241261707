$(function() {
  addScrollPositionEventListeners()
})

const addScrollPositionEventListeners = () => {
  $(document).on('brix:widgets:initialized', () => setScrollPositionToAnchoredPortlet())
  $(window).on('hashchange', (event) => {
    if (isDashboardShowVisit()) {
      const newHash = getHashFromEvent(event);
      setScrollPositionToAnchoredPortlet(newHash);
    }
  })
}

const isDashboardShowVisit = () => $('html').find("body.dashboards.show").length

const setScrollPositionToAnchoredPortlet = (targetHash = hashTarget()) => {
  if (anchoredPortletPresentOnPage(targetHash)) {
    disableScrollRestorationUntilReload()
    $(document).scrollTop(anchoredPortletScrollTopWithHeaderOffsets(targetHash))
    triggerAnchorAnimation(targetHash)
  }
}

const getHashFromEvent = (event) => {
  const newURL = event.originalEvent?.newURL
  return newURL ? new URL(newURL).hash : window.location.hash
}

const hashTarget = () => {
  const namespacePrefix = "#brix_"
  const hash = window.location.hash.match(/#(brix_)?portlet_\d+/)?.[0]

  if (!hash || hash.startsWith(namespacePrefix)) {
    return hash;
  } else {
    return hash.replace("#", namespacePrefix)
  }
}

const anchoredPortletPresentOnPage = (targetHash) => $(targetHash).length

const disableScrollRestorationUntilReload = () => {
  history.scrollRestoration = "manual";
  $(document).one("turbo:load", () => history.scrollRestoration = "auto")
}

const anchoredPortletScrollTopWithHeaderOffsets = (targetHash) => {
  const navbarOffset = $(".navbar__container").first().height();
  const actionsMenuOffset = $(".dashboard .actions").first().height();
  const breathingRoomOffset = 10;

  return $(targetHash).offset().top - (navbarOffset + actionsMenuOffset + breathingRoomOffset);
}

const triggerAnchorAnimation = (targetHash) => {
  const anchorClass = "portlet--anchored";

  $(`.${anchorClass}`).removeClass(anchorClass);
  $(targetHash).addClass(anchorClass);
}
