// Performance issue in sortable widget with long lists
// https://github.com/jquery/jquery-ui/issues/2062
$.ui.sortable.prototype._setHandleClassName = function() {
    this._removeClass( this.element.find( ".ui-sortable-handle" ), "ui-sortable-handle" );
    $.each( this.items, function() {
        (this.instance.options.handle
         ? this.item.find( this.instance.options.handle )
         : this.item
        ).addClass('ui-sortable-handle');
    });
};
