import { DomLike } from "./dom_like"
import { ResultFilter } from "./result_filter"
import { EXPERIMENT_DATA_SOURCE_CHANGED, RESULT_FILTERS_ADDED, RESULT_FILTERS_REMOVED, WIDGET_FILTERS_UPDATED } from "./events"

const ADD_RESULT_FILTERS_LINK = '#add-additional-result-filters'
const ADDITIONAL_RESULT_FILTERS_WRAPPER = '#additional-result-filters'
const DATA_SOURCE_SELECTOR = '#dashboard_template_config_brix_experiment_data_source_id'
const FORM_SELECTOR = '#dashboard-template-config__form'
const REMOVE_RESULT_FILTERS_LINK = '#remove-additional-result-filters'
const RESULT_FILTERS_SECLECTOR = '.result-filters'

export class Form extends DomLike {
  constructor() {
    super($(FORM_SELECTOR))

    this.$dataSourceDropdown = this.find(DATA_SOURCE_SELECTOR)
    this.$additionalResultFilters = this.find(ADDITIONAL_RESULT_FILTERS_WRAPPER)
    this.$addResultFilters = this.find(ADD_RESULT_FILTERS_LINK)
    this.$removeResultFilters = this.find(REMOVE_RESULT_FILTERS_LINK)

    this.dataSourceId = this.$dataSourceDropdown.val()
    this.widgetFilters = []
    this.resultFilters = []

    this.setupResultFilters()
    this.setupDataSource()
    this.setupWidgetFilters()
    this.setupAddResultFiltersLinks()
  }

  setupResultFilters() {
    this.resultFilters = []

    this.find(RESULT_FILTERS_SECLECTOR).each((_, elt) => {
      this.resultFilters.push(new ResultFilter(elt, this))
    })
  }

  get widgetFiltersUrlTemplate() {
    return this.data('widgetFiltersUrlTemplate')
  }

  get filterValuesUrlTemplate() {
    return this.data('filterValuesUrlTemplate')
  }

  setupDataSource() {
    this.sub(EXPERIMENT_DATA_SOURCE_CHANGED, () => { this.setDataSourceId() })
    this.sub(EXPERIMENT_DATA_SOURCE_CHANGED, () => { this.refreshWidgetFilters() })

    this.$dataSourceDropdown.on('change', () => { this.pub(EXPERIMENT_DATA_SOURCE_CHANGED) })
  }

  setupWidgetFilters() {
    this.loadWidgetFilters()
  }

  setupAddResultFiltersLinks() {
    this.$addResultFilters.on('click', 'a', (e) => { this.addResultFilters(e) })
    this.$removeResultFilters.on('click', 'a', (e) => { this.removeResultFilters(e) })
  }

  setDataSourceId() {
    this.dataSourceId = this.$dataSourceDropdown.val()
  }

  async refreshWidgetFilters() {
    await this.loadWidgetFilters()
    this.notifyCollection(this.resultFilters, WIDGET_FILTERS_UPDATED)
  }

  async loadWidgetFilters() {
    const urlTemplate = this.widgetFiltersUrlTemplate
    const url = urlTemplate.replaceAll('__id__', () => this.dataSourceId);

    return $.getJSON(url).then((data) => { this.widgetFilters = data })
  }

  primaryFilter() {
    return this.resultFilters[0]
  }

  addResultFilters(e) {
    e.preventDefault()

    this.$additionalResultFilters.show()
    this.$addResultFilters.hide()
    this.notifyCollection(this.resultFilters, RESULT_FILTERS_ADDED)
  }

  removeResultFilters(e) {
    e.preventDefault()

    this.$additionalResultFilters.hide()
    this.$addResultFilters.show()
    this.notifyCollection(this.resultFilters, RESULT_FILTERS_REMOVED)
  }
}
