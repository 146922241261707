import { merge } from "lodash";

import GridItemConfigurationPanel from "./grid_item_configuration_panel";
import GridItemLock from "./grid_item_lock";
import GridItemEditModeOverlay from "./grid_item_edit_mode_overlay";
import GridItemForm from "./grid_item_form";

import * as WidgetTypes from 'brix/widgets';

class GridItem {
  constructor($root, grid) {
    this.$root = $root;
    this.grid = grid;

    this.id = this.$root.attr("id");
    this.element = this.$root.get(0);

    this.widget = null;
    this.$widget = this.$root.find(".widget");

    this.form = new GridItemForm(this);
    this.lock = new GridItemLock(this);
    this.editModeOverlay = new GridItemEditModeOverlay(this);
    this.configurationPanel = new GridItemConfigurationPanel(this);

    this._setup();
  }

  focus = () => {
    this.configurationPanel.close();
    this.editModeOverlay.focus();
  };

  unfocus = () => {
    this.configurationPanel.close();
    this.editModeOverlay.unfocus();
  };

  initialize = () => {
    const widgetAttributes = merge(this.$widget.data(), { el: this.$widget });

    this.widget = new WidgetTypes[widgetAttributes.widgetType](widgetAttributes);
  };

  redraw = () => this.widget?.redraw();
  refresh = () => this.widget?.refresh() && this.form.refresh();

  _setup = () => {
    this._registerEditIconClickHandler();
    this._registerExportIconClickHandler();
    this._registerDuplicateDropdownClickHandler();
    this._registerRemoveIconClickHandler();
    this._registerLockIconClickHandler();
  };

  _registerEditIconClickHandler = () => this.$root.find(".portlet__icon.edit").on("click", this._toggleEditMode);

  _toggleEditMode = () => (this.form.isOpen ? this.disableEditMode() : this.enableEditMode());

  enableEditMode = () => {
    this.grid.disableEditMode();
    this.grid.unfocus();
    this.focus();

    this.form.open();
  };

  disableEditMode = () => {
    this.form.close();
    this.grid.focus();
  };

  _registerExportIconClickHandler = () =>
    this.$root
      .find(".portlet__icon.export")
      .on("click", () => (document.location.href = this.$root.find(".widget").data("export-url")));

  _registerDuplicateDropdownClickHandler = () => this.$root.find(".duplicate__dropdown a").on("click", this._duplicate);

  _duplicate = (e) => {
    e.preventDefault();
    const duplicateUrl = this.$root.data("duplicate-url");
    const duplicateLocation = $(e.currentTarget).data("duplicate");

    this.disableEditMode();
    $.post(duplicateUrl, { location: duplicateLocation });
  };

  _registerRemoveIconClickHandler = () =>
    this.$root.find(".portlet__icon.remove").on("click", () => {
      if (confirm("Really delete this widget?")) {
        this.disableEditMode();
        this.grid.removeGridItem(this, this.$root.data("destroy-url"));
      }
    });

  _registerLockIconClickHandler = () => this.$root.find(".portlet__icon.lock").on("click", this._toggleLock);

  _toggleLock = () => (this.lock.isLocked ? this.unlock() : this._lock());

  _lock = () => {
    this.grid.update(this.element, { locked: true, noMove: true, noResize: true });
    this.lock.lock();
    this.editModeOverlay.lock();
  };

  unlock = () => {
    this.grid.update(this.element, { locked: false, noMove: false, noResize: false });
    this.lock.unlock();
    this.editModeOverlay.unlock();
  };
}

export default GridItem;
