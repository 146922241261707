class AddTreatmentButton {
  constructor(el, maxTreatmentCount) {
    this.$el = $(el);
    this.maxTreatmentCount = maxTreatmentCount;
    this.disabled = false;

    this.createClickHandler();
  }

  createClickHandler() {
    this.$el.on("click", (e) => {
      if (this.disabled) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  setTreatmentCount(count) {
    this.disabled = count >= this.maxTreatmentCount;

    this.$el
      .attr("disabled", this.disabled)
      .toggleClass("disabled", this.disabled);
  }
}

export default AddTreatmentButton;
