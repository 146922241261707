import Component from '../../store/lib/component.js';

export const EXPECTED_LIFT_SELECTOR = '#elc-expected-lift__value';
export const URL_PARAMETER = 'expected-lift';

export default class ExpectedLift extends Component {
  constructor(props = {}) {
    super({
      ...props,
      urlParameter: URL_PARAMETER
    });

    $(EXPECTED_LIFT_SELECTOR).on('change keyup input', (e) => {
      this.store.commit('expectedLift', parseFloat($(e.target).val()));
      this.store.events.publish('runCalculator');
    });

    $(EXPECTED_LIFT_SELECTOR).on('change', (e) => {
      this.dataStorage.addOrModify(this.urlParameter, $(e.target).val());
    });
  }

  initialize() {
    const val = parseFloat(this.dataStorage.get(this.urlParameter));

    if (!isNaN(val)) {
      $(EXPECTED_LIFT_SELECTOR).val(val);
      this.store.commit('expectedLift', val );
    }
  }
}
