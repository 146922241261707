import { reject } from "lodash";

import { GridStack } from "gridstack";

import GridItem from "./grid_item";

class Grid {
  constructor($root, dashboard) {
    this.$root = $root;
    this.dashboard = dashboard;

    this.gridState = GridStack.init({
      cellHeight: 140,
      maxRow: 250,
      alwaysShowResizeHandle: true,
    });

    this.updateUrl = this.$root.data('update-url');

    this.enable = this.gridState.enable.bind(this.gridState);
    this.disable = this.gridState.disable.bind(this.gridState);
    this.update = this.gridState.update.bind(this.gridState);

    this.gridItems = null;
    this._setup();
  }

  addGridItem = (html, size_x, size_y, col, row) => {
    const result = this.gridState.addWidget(html, { w: size_x, h: size_y, x: col, y: row });
    const gridItem = new GridItem($(result), this);

    this.gridItems.push(gridItem);

    gridItem.initialize();
    gridItem.enableEditMode();

    this._toggleEmptyStateMessage();
  };

  refreshGridItem = (id) => this.gridItems.find((gridItem) => gridItem.id == id).refresh();

  removeGridItem = (gridItem, destroyUrl) => {
    $.ajax({
      url: destroyUrl,
      method: "DELETE",
      success: () => {
        this.gridState.removeWidget(gridItem.element);
        this.gridItems = reject(this.gridItems, gridItem);

        this._toggleEmptyStateMessage();
      },
    });
  };

  _setup = () => {
    this._registerGridstackEventCallbacks();
    this._initializeGridItems();
    this._toggleEmptyStateMessage();
  };

  _registerGridstackEventCallbacks = () => {
    this.gridState.on("added", this.enable)
    this.gridState.on("added", this._updateGridState)
    this.gridState.on("removed", this._updateGridState)

    this.gridState.on("enable", this.focus);
    this.gridState.on("disable", this._disableGrid);

    this.gridState.on("resizestart", this.disableEditMode);
    this.gridState.on("resizestop", this._updateGridState);

    this.gridState.on("dragstart", this.disableEditMode);
    this.gridState.on("dragstop", this._updateGridState);
  };

  _initializeGridItems = () => {
    document.dispatchEvent(new Event("brix:widgets:initialized"));

    this.gridItems = this.gridState.getGridItems().map((node) => new GridItem($(node), this));
    this.gridItems.forEach((gridItem) => gridItem.initialize());
  };

  _toggleEmptyStateMessage = () => $("#empty-state-message").toggleClass("d-none", this.gridItems.length > 0);

  _updateGridState = (event, updatedGridStateItem) => {
    const portletAttributes = this.gridState.save().map((item) => ({
      id: item.id,
      row: item.y,
      column: item.x,
      size_x: item.w || 1,
      size_y: item.h || 1
    }));

    $.ajax({
      method: "PATCH",
      url: this.updateUrl,
      data: { dashboard: { portlets_attributes: portletAttributes } },
      success: () => {
        if (event.type == "resizestop") {
          this._redrawGridItem(updatedGridStateItem)
        }
      }
    })
  }

  _disableGrid = () => {
    this.disableEditMode();
    this.gridItems.forEach((gridItem) => gridItem.unlock());
  };

  disableEditMode = () => this.gridItems.forEach((gridItem) => gridItem.disableEditMode());

  _redrawGridItem = (gridStateItem) =>
    this.gridItems.find((gridItem) => gridItem.id == gridStateItem.id).redraw();

  focus = () => this.gridItems.forEach((gridItem) => gridItem.focus());
  unfocus = () => this.gridItems.forEach((gridItem) => gridItem.unfocus());
}

export default Grid;
