import { select, scaleLinear } from 'd3';

const min = -2;
const max = 2;
const height = 22;
const width = 160;
const xPadding = 16;
const tickHeight = 13;
const ciRectHeight = 9;

const initSidePreference = (element) => {
  $(element).find('.side-preference-score, .weighted-side-preference-score').each((_idx, elm) => {
    $(elm).empty();

    let { score, ci } = $(elm).data()

    if (score === '') {
      return
    } else {
      score = Number(score)
    }

    ci = Number(ci) || 0

    const svg = select(elm)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const x = scaleLinear()
      .domain([min, max])
      .range([xPadding, width - xPadding]);

    // horizontal line
    svg
      .append("line")
      .attr("x1", x(min))
      .attr("y1", height - (tickHeight / 2))
      .attr("x2", x(max))
      .attr("y2", height - (tickHeight / 2))
      .style("stroke", "var(--bs-body-color)")
      .style("stroke-width", 1)

    // left vertical tick
    svg
      .append("line")
      .attr("x1", x(min))
      .attr("y1", height - tickHeight)
      .attr("x2", x(min))
      .attr("y2", height)
      .style("stroke", "var(--bs-body-color)")
      .style("stroke-width", 1)

    // center vertical tick
    svg
      .append("line")
      .attr("x1", x(0))
      .attr("y1", height - tickHeight)
      .attr("x2", x(0))
      .attr("y2", height)
      .style("stroke", "var(--bs-body-color)")
      .style("stroke-width", 1)

    // right vertical tick
    svg
      .append("line")
      .attr("x1", x(max))
      .attr("y1", height - tickHeight)
      .attr("x2", x(max))
      .attr("y2", height)
      .style("stroke", "var(--bs-body-color)")
      .style("stroke-width", 1)

    // the ci horizontal bar
    svg
      .append('rect')
      .attr('x', x(score - ci))
      .attr('y', height - (tickHeight / 2) - (ciRectHeight / 2))
      .attr('width', Math.min(x(score + ci), x(max)) - Math.max(x(score - ci), x(min)))
      .attr('height', ciRectHeight)
      .attr('fill', 'var(--bs-gray-400)')

    // the blue dot
    svg
      .append("circle")
      .attr("cx", x(score))
      .attr("cy", height - (tickHeight / 2))
      .attr("r", 3)
      .style("fill", "var(--bs-primary)")

    // the numeric score above the blue dot
    const sig = (score - ci > 0) && 'positive' || (score + ci < 0) && 'negative' || 'none'

    svg
      .append("text")
      .attr("x", x(score))
      .attr("y", 8)
      .attr("class", `significance-${sig}`)
      .style("font-size", 10)
      .style("text-anchor", "middle")
      .text(ci ? `${score} ±${ci}` : score)
  });
}

$(document).on('turbo:load', () => { initSidePreference(document) });
$(document).on('side_preference:update', (e) => { initSidePreference(e.target) });
