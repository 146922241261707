$(document).on('change', '.toggle-judgeable', (e) => {
  const $checkBox = $(e.target)
  const data = {
    [$checkBox.data('resource-name')]: { judgeable: $checkBox.is(':checked') }
  }

  $.ajax({
    url: $checkBox.data('url'),
    method: 'PUT',
    data: data,
    success: () => {
      $checkBox.siblings('.confirmation-icon').flashConfirmationIcon()
    }
  })
})
