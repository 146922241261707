import { merge } from 'lodash';

import BaseOptions from './base_options';
import formatterFor from '../formatter_for';

class HorizontalBarChartOptions extends BaseOptions {
  static defaultOptions() {
    return merge(super.defaultOptions(), {
      chart: {
        type: 'bar'
      },
      dataLabels: {
        offsetY: -1,
        textAnchor: 'start',
        enabled: true,
      },
      fill: {
        opacity: 1
      },
      grid: {
        padding: {
          right: 60,
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '90%',
          dataLabels: {
            position: 'top'
          }
        },
      },
      states: {
        hover: {
          filter: 'none'
        }
      },
      stroke: {
        show: true,
        width: 4,
        colors: ['transparent']
      },
      tooltip: {
        enabled: false
      },
      yaxis: {
        labels: {
          offsetX: 0
        },
        title: {
          offsetX: 10
        }
      }
    });
  }

  constructor(chartData) {
    super(chartData);

    this.dataDependentOptions = this.setDataDependentOptions();
  }

  build = () => merge(
    {
      chart: {
        events: {
          beforeMount: this.hideHorizontalDataLabels,
          mounted: this.repositionAndUnhideHorizontalDataLabels,
          updated: this.repositionAndUnhideHorizontalDataLabels
        }
      }
    },
    this.constructor.defaultOptions(),
    this.chartData,
    this.dataDependentOptions,
    this.isPresentationMode() ? this.presentationOptions : {}
  )

  hideHorizontalDataLabels = (_context, options) => options.config.dataLabels.style.colors = ["transparent"]

  repositionAndUnhideHorizontalDataLabels = (context, _options) => {
    const $chart = $(context.el);
    const $bars = $(".apexcharts-bar-area");
    const $dataLabels = $(".apexcharts-datalabels text");

    const barPositions = $.map($chart.find($bars), (e) => parseFloat(e.attributes.barWidth.value));

    const dataLabelOffsetX = 8;
    const updatedLabelPositions = barPositions.map((barPosition) => barPosition + dataLabelOffsetX);

    $.map($chart.find($dataLabels), (e, i) => {
      e.setAttribute('x', updatedLabelPositions[i]);
      e.setAttribute('fill', 'var(--bs-body-color)')
    })
  }

  setDataDependentOptions = () =>
    ({
      dataLabels: {
        formatter: formatterFor(this.chartData.meta.data_labels_format)
      },
      title: {
        text: this.chartData.meta.title_text
      },
      xaxis: {
        categories: this.chartData.meta.dimension_categories,
        title: {
          text: this.chartData.meta.measure_title_text
        },
        labels: {
          formatter: formatterFor(this.chartData.meta.measure_labels_format)
        }
      },
      yaxis: {
        title: {
          text: this.chartData.meta.dimension_title_text
        },
        labels: {
          offsetX: 0
        }
      },
    })
}

export default HorizontalBarChartOptions;
