class GridItemLock {
  constructor(gridItem) {
    this.$root = gridItem.$root.find(".portlet__icon.lock");

    this.isLocked = false;
  }

  unlock = () => {
    this.$root.removeClass("lock--locked");
    this.isLocked = false;
  };

  lock = () => {
    this.$root.addClass("lock--locked");
    this.isLocked = true;
  };
}

export default GridItemLock;
