// To use:
// 1. add class "ajax-property-updatable" to your checkbox or other element
// 2. make sure to set the following data attributes: url, method, model_name, attr_name, params
// 3. if not using a checkbox, add `value` to your data attributes
// 4. Add `confirmation_and_warning_icons` to your html if you want the confirmation flash

// For example:
/*
<%= check_box_tag(
  :active,
  task.active?,
  task.active?,
  class: 'ajax-property-updatable',
  data: {
    url: scrape_lpa_task_path(@scrape, task, format: :js),
    method: 'PATCH',
    model_name: 'task',
    attr_name: 'active',
    params: {
      id: task.id,
      scrape_id: @scrape.id
    }
  }) + confirmation_and_warning_icons %>
*/

const updateProperty = function() {
  const el = $(this)
  el.removeClass('ajax-property-updatable');

  const data = el.data()
  const attrName = data.attrName
  const value = data.value
  const modelName = data.modelName
  const params = {}
  params[modelName] = data.params
  if (value) {
    params[modelName][attrName] = value
  } else {
    params[modelName][attrName] = el.is(':checked')
  }

  const options = {
    url: data.url,
    method: data.method,
    timeout: 3000,
    data: params
  }

  $.ajax(options)
    .done(function() {
      el.siblings('.confirmation-and-warning-icons').find('.confirmation-icon').flashConfirmationIcon()
    })
    .fail(function() {
      el.siblings('.confirmation-and-warning-icons').find('.warning-icon').flashConfirmationIcon()
    });
}

$(document).on('change click', '.ajax-property-updatable', updateProperty);
