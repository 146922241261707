import BaseWidget from "./base_widget";

class MarkdownWidget extends BaseWidget {
  load = () => $.get(this.attributes.dataUrl, this.redraw).fail(this.handleLoadFailure)

  redraw = (html) => this.setHtml(html).find("table").addClass("table")

  handleLoadFailure = (xhr) => this.setHtml(xhr.statusText)
}

export default MarkdownWidget;
