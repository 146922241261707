const MEASURES_SELECTOR = "form.edit_portlet .measures-fields";
const DIMENSIONS_SELECTOR = "form.edit_portlet .dimensions";

const applyLastClass = () =>
  $(MEASURES_SELECTOR).find(".nested-fields").removeClass("last").filter(":visible").last().addClass("last");

const defaultToNextOption = (cocoonContainer) => {
  $(document).on("cocoon:before-insert", cocoonContainer, (e, item) => {
    const selectedValues = $("select:visible", cocoonContainer).map(() => $(this).val());
    const options = $("option", item).map(() => $(this).val());
    const nextOption = $(options).not(selectedValues).get()[0];

    if (nextOption) {
      $("select", item).val(nextOption);
    }
  });
};

const registerSortableCocoonCallbacks = () => {
  $(document).on("cocoon:after-remove cocoon:after-insert", MEASURES_SELECTOR, applyLastClass);

  defaultToNextOption(MEASURES_SELECTOR);
  defaultToNextOption(DIMENSIONS_SELECTOR);

  $(document).on("cocoon:after-insert", MEASURES_SELECTOR, () => {
    $(MEASURES_SELECTOR).animate({ scrollTop: $(MEASURES_SELECTOR).prop("scrollHeight") }, 400);
  });

  $(document).on("sortupdate", MEASURES_SELECTOR, (e, _ui) => {
    applyLastClass();

    const $container = $(e.currentTarget);

    $container
      .find($container.sortable("option", "items"))
      .find("input.position")
      .each((i, inputField) => $(inputField).val(i + 1));
  });
};

const registerToggleSortDirectionCallback = () => {
  const toggleDirectionSelector = '#edit_portlet .toggle-sort-direction';
  const alreadyRegistered =
    $._data(document, 'events').click.find((event) => event.selector == toggleDirectionSelector)

  if (alreadyRegistered) {
    return;
  }

  $(document).on('click', toggleDirectionSelector, (e) => {
    const input = $(e.currentTarget).siblings('input.sort-direction');

    const nextPropertiesForInput = {
      "asc": {
        "value": "desc",
        "class": "glyphicon-sort-by-attributes-alt"
      },
      "desc": {
        "value": null,
        "class": "glyphicon-sort"
      },
      "": {
        "value": "asc",
        "class": "glyphicon-sort-by-attributes"
      }
    }

    const next = nextPropertiesForInput[input.val()]

    input.val(next.value)
    $(e.currentTarget)
      .removeClass('glyphicon-sort-by-attributes glyphicon-sort-by-attributes-alt glyphicon-sort')
      .addClass(next.class)
  })
}

const setSortableBehavior = () =>
  $(MEASURES_SELECTOR).sortable({
    handle: ".drag-handle",
    items: ".nested-fields",
    axis: "y",
    opacity: "0.5",
  });

const unsetSortableBehavior = () => $(MEASURES_SELECTOR).sortable("destroy");

const SortableForm = {
  _registerSortableFormCallbacks() {
    registerSortableCocoonCallbacks();
    registerToggleSortDirectionCallback();
  },
  _setSortableBehavior() {
    setSortableBehavior();
  },
  _unsetSortableBehavior() {
    unsetSortableBehavior();
  }
};

export default SortableForm;
