class Timeframe {
  constructor(el) {
    this.$el = $(el);
    this.$startsAtContainer = this.$el.find('input[name$="[starts_at]"]').closest(".datetime-picker-input");
    this.$endsAtContainer   = this.$el.find('input[name$="[ends_at]"]').closest(".datetime-picker-input");
    this.$storefrontsSelect = this.$el.find("#experiment_storefront_ids");

    this.$startsAtContainer.on("change.td", () => this.handleChange());
    this.$endsAtContainer.on("change.td", () => this.handleChange());
    this.$storefrontsSelect.on("change", () => this.handleChange());
  }

  startsAt() {
    return this.$startsAtContainer.find("input").val();
  }

  endsAt() {
    return this.$endsAtContainer.find("input").val();
  }

  storefrontIds(){
    return this.$storefrontsSelect.val();
  }

  handleChange(_) {
    $(this).trigger("change", {
      starts_at: this.startsAt(),
      ends_at: this.endsAt(),
      storefront_ids: this.storefrontIds()
    });
  }
}

export default Timeframe;
