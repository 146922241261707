import ApexCharts from "apexcharts";

import BaseWidget from "./base_widget";
import BoxPlotOptions from "apex/utils/chart_options/box_plot_options";
import WithWidgetOptions from "apex/utils/chart_options/extenders/with_widget_options";

class ABExperimentPercentileReadoutWidget extends BaseWidget {
  load = () => $.getJSON(this.attributes.dataUrl, this.updateChartData).fail(this.handleError)

  updateChartData = (data) => {
    this.destroyChart();
    this.element.empty();

    if (this.hasPercentileData(data)) {
      const options = new WithWidgetOptions(data, BoxPlotOptions).build();

      this.chart = new ApexCharts(this.element.get(0), options)
      this.chart.render();
    } else {
      this.noData(data.meta.error_message || "No data available");
    }
  }

  handleError = (xhr) => this.noData(xhr.responseJSON.message)

  hasPercentileData = (data) => data.percentiles[0].y.length > 0 && data.percentiles[1].y.length > 0

  noData = (message) => {
    this.element.append(() => {
      return $("<div>").addClass("no-data-container").append(() => {
        return $("<div>").addClass("no-data").text(message);
      });
    });
  }

  destroyChart = () => {
    this.chart?.destroy();
    this.chart = null;
  }
}

export default ABExperimentPercentileReadoutWidget;
