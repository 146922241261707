import formatterFor from 'apex/utils/formatter_for';
import VerticalBarChartOptions from "./vertical_bar_chart_options";

class VerticalBarChartLpaDashboardOptions extends VerticalBarChartOptions {

  constructor(chartData) {
    super(chartData);

    this.dataDependentOptions = this.setDataDependentOptions();
  }

  setDataDependentOptions = () => ({
    chart: {
      height: '275',
    },
    title: {
      text: this.chartData.meta.title_text,
      align: 'left',
      style: {
        fontSize: '1rem',
        fontWeight: 'normal',
        color: 'var(--bs-secondary)',
      }
    },
    xaxis: {
      categories: this.chartData.meta.dimension_categories
    },
    yaxis: {
      title: {
        text: this.chartData.meta.measure_title_text
      },
      labels: {
        formatter: formatterFor(this.chartData.meta.measure_labels_format)
      }
    },
  })
}

export default VerticalBarChartLpaDashboardOptions;
