import { merge } from 'lodash';

class BaseOptions {
  static defaultOptions() {
    return {
      chart: {
        background: 'var(--bs-body-bg)',
        animations: { enabled: false },
        toolbar: { show: false },
      },
      grid: {
        borderColor: 'var(--bs-border-color)',
      },
      legend: {
        position: 'top',
        markers: { radius: '50' }
      },
      title: {
        align: 'center',
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          color: 'var(--bs-body-color)'
        }
      },
      colors: [
        'var(--bs-blue)',
        'var(--bs-green)',
        'var(--bs-yellow)',
        'var(--bs-red)',
        'var(--bs-orange)',
        'var(--bs-cyan)',
        'var(--bs-indigo)',
        'var(--bs-teal)',
        'var(--bs-pink)',
        'var(--bs-purple)',
      ],
      dataLabels: {
        style: {
          fontWeight: 'normal',
          colors: ['var(--bs-body-color)'],
        }
      }
    };
  }

  get presentationOptions() {
    return {
      theme: {
        mode: 'dark',
      }
    };
  }

  constructor(chartData) {
    this.chartData = chartData;
    this.dataDependentOptions = {};
  }

  build = () => merge(
    {},
    this.constructor.defaultOptions(),
    this.chartData,
    this.dataDependentOptions,
    this.isPresentationMode() ? this.presentationOptions : {}
  )

  isPresentationMode = () => document.body.classList.contains('presentation')
}

export default BaseOptions;
