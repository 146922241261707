import BaseWidget from "./base_widget";
import DataTableOptionsBuilder from "brix/utils/data_table_options_builder";

class TabularIntervalWidget extends BaseWidget {
  load = () => $.get(this.attributes.dataUrl, this.initializeTable)

  initializeTable = (html) => {
    this.setHtml(html);

    const $table = this.element.find("table").css({ width: "100%" });

    const builder = new DataTableOptionsBuilder()
      .dom("ftpl")
      .emptyTable($table.data("error-message"))
      .default({ ordering: false });

    $table.DataTable(builder.call()).draw();
  }
}

export default TabularIntervalWidget;
