$(document).on("shown.bs.popover", ".dashboard", (e) => {
  const $body = $("html, body");
  const $dashboard = $(e.currentTarget);
  const $popoverTarget = $(e.target);
  const $footer = $("footer")

  const $popover = $dashboard.find(".popover");

  const popoverNotFound = $popover.length == 0;
  const popoverTargetIsNotWidget = !$popoverTarget.hasClass("widget");

  if (popoverNotFound || popoverTargetIsNotWidget) {
    return;
  }

  const topOf = ($element) => $element.offset().top
  const heightOf = ($element) => $element.outerHeight(true)
  const bottomOf = ($element) => topOf($element) + heightOf($element);

  const bottomOfWidget = bottomOf($popoverTarget)
  const bottomOfBodyExcludingFooter = bottomOf($body) - heightOf($footer);

  const spaceBetweenWidgetAndBody = bottomOfBodyExcludingFooter - bottomOfWidget;

  const setBodyHeightToCreateSpaceForPopover = () =>
    $body.height(heightOf($body) + heightOf($popover) - spaceBetweenWidgetAndBody)

  const scrollToBottomOfBody = () => {
    const desiredScrollPosition = bottomOf($body) - heightOf($footer) - window.innerHeight;

    window.scrollTo({
      top: desiredScrollPosition,
      left: 0,
      behavior: 'smooth'
    })
  }

  const scrollToAlignBottomOfPopoverWithWindow = () => {
    const visualSeparationOffset = 5
    const bottomOfWindow = window.scrollY + window.innerHeight;
    const spaceBetweenWindowAndPopover = bottomOf($popover) - bottomOfWindow;

    const popoverIsOverflowingWindow = spaceBetweenWindowAndPopover > 0;

    if (popoverIsOverflowingWindow) {
      const desiredScrollPosition = (
        window.scrollY +
        spaceBetweenWindowAndPopover +
        visualSeparationOffset
      )

      window.scrollTo({
        top: desiredScrollPosition,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  const popoverFitsIntoAvailableSpace = heightOf($popover) < spaceBetweenWidgetAndBody

  if (popoverFitsIntoAvailableSpace) {
    scrollToAlignBottomOfPopoverWithWindow();
  } else {
    setBodyHeightToCreateSpaceForPopover() && scrollToBottomOfBody();
  }
});
