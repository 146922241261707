import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).on("cocoon:after-remove cocoon:after-insert", this.setPositionFieldValues.bind(this));

    this.setPositionFieldValues()

    $(this.element).sortable({
      items: '.drag-container',
      handle: '.drag-handle',
      stop: (_, ui) => this.setPositionFieldValues()
    })
  }

  setPositionFieldValues() {
    $(this.element).find('.position-field').each((i, input) => input.value = i);
  }

  disconnect() {
    $(this.element).sortable('destroy')
  }
}
