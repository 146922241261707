// https://stackoverflow.com/a/36277418/1133199
const fuzzyMatch = function(str, pattern) {
  str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, '');

  // Score consts
  const adjacency_bonus = 5;                // bonus for adjacent matches
  const separator_bonus = 10;               // bonus if match occurs after a separator
  const camel_bonus = 10;                   // bonus if match is uppercase and prev is lower
  const leading_letter_penalty = -3;        // penalty applied for every letter in str before the first match
  const max_leading_letter_penalty = -9;    // maximum penalty for leading letters
  const unmatched_letter_penalty = -1;      // penalty for every letter that doesn't matter

  // Loop variables
  let score = 0;
  let patternIdx = 0;
  const patternLength = pattern.length;
  let strIdx = 0;
  const strLength = str.length;
  let prevMatched = false;
  let prevLower = false;
  let prevSeparator = true;       // true so if first letter match gets separator bonus

  // Use "best" matched letter if multiple string letters match the pattern
  let bestLetter = null;
  let bestLower = null;
  let bestLetterIdx = null;
  let bestLetterScore = 0;

  const matchedIndices = [];

  // Loop over strings
  while (strIdx != strLength) {
    const patternChar = patternIdx != patternLength ? pattern.charAt(patternIdx) : null;
    const strChar = str.charAt(strIdx);

    const patternLower = patternChar != null ? patternChar.toLowerCase() : null;
    const strLower = strChar.toLowerCase();
    const strUpper = strChar.toUpperCase();

    const nextMatch = patternChar && patternLower == strLower;
    const rematch = bestLetter && bestLower == strLower;

    const advanced = nextMatch && bestLetter;
    const patternRepeat = bestLetter && patternChar && bestLower == patternLower;
    if (advanced || patternRepeat) {
      score += bestLetterScore;
      matchedIndices.push(bestLetterIdx);
      bestLetter = null;
      bestLower = null;
      bestLetterIdx = null;
      bestLetterScore = 0;
    }

    if (nextMatch || rematch) {
      let newScore = 0;

      // Apply penalty for each letter before the first pattern match
      // Note: std::max because penalties are negative values. So max is smallest penalty.
      if (patternIdx == 0) {
        const penalty = Math.max(strIdx * leading_letter_penalty, max_leading_letter_penalty);
        score += penalty;
      }

      // Apply bonus for consecutive bonuses
      if (prevMatched)
        newScore += adjacency_bonus;

      // Apply bonus for matches after a separator
      if (prevSeparator)
        newScore += separator_bonus;

      // Apply bonus across camel case boundaries. Includes "clever" isLetter check.
      if (prevLower && strChar == strUpper && strLower != strUpper)
        newScore += camel_bonus;

      // Update patter index IFF the next pattern letter was matched
      if (nextMatch)
        ++patternIdx;

      // Update best letter in str which may be for a "next" letter or a "rematch"
      if (newScore >= bestLetterScore) {

        // Apply penalty for now skipped letter
        if (bestLetter != null)
          score += unmatched_letter_penalty;

        bestLetter = strChar;
        bestLower = bestLetter.toLowerCase();
        bestLetterIdx = strIdx;
        bestLetterScore = newScore;
      }

      prevMatched = true;
    }
    else {
      score += unmatched_letter_penalty;
      prevMatched = false;
    }

    // Includes "clever" isLetter check.
    prevLower = strChar == strLower && strLower != strUpper;
    prevSeparator = strChar == '_' || strChar == ' ';

    ++strIdx;
  }

  // Apply score for last match
  if (bestLetter) {
    score += bestLetterScore;
    matchedIndices.push(bestLetterIdx);
  }

  // Finish out formatted string after last pattern matched
  // Build formated string based on matched letters
  let formattedStr = "";
  let lastIdx = 0;
  for (let i of matchedIndices) {
    const idx = matchedIndices[i];
    formattedStr += str.substr(lastIdx, idx - lastIdx) + "<b>" + str.charAt(idx) + "</b>";
    lastIdx = idx + 1;
  }
  formattedStr += str.substr(lastIdx, str.length - lastIdx);

  const matched = patternIdx == patternLength;
  return [matched, score, formattedStr];
}

export default fuzzyMatch;
