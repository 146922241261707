function updateTreatmentDropdowns(experimentSelect) {
  const $experimentSelect = $(experimentSelect);
  const experimentId = $experimentSelect.val();
  const $treatmentSelects = $experimentSelect.closest('.ab-treatment-configuration').find('.ab-treatment-select');
  const url = $experimentSelect.data('urlTemplate').replace(':id', experimentId);

  $.getJSON(url, (treatments) => {
    const treatmentIndices = {};

    // get the indexes of the currently selected treatments
    $treatmentSelects.each((_, el) => treatmentIndices[el.id] = el.selectedOptions[0]?.index || 0);

    // replace the current treatment options with the treatments of the new experiment
    $treatmentSelects.empty();
    treatments.forEach((treatment) => {
      $treatmentSelects.append(
        $('<option>').attr('value', treatment.id).text(treatment.name_with_key)
      );
    });
    $treatmentSelects.prepend($('<option>').attr('value', undefined));

    // select treatments corresponding to the previously selected indexes
    $treatmentSelects.each((_, el) => el.selectedIndex = treatmentIndices[el.id]);
  });
}

export default updateTreatmentDropdowns;
