import * as d3 from 'd3';

const isNaNString = (str) => isNaN(str) || isNaN(parseFloat(str))

const currencyFormatString = (value) => {
  const absValue = Math.abs(value);

  if (absValue < 0.01) return '$.2f';
  if (absValue < 1)    return '$.3f';
  if (absValue < 10)   return '$.3s';
  if (absValue < 100)  return '$.4s';
  else                 return '$.3~s';
}

const dateFormatString = () => '%d %b'

const datetimeFormatString = () => '%d %b %H:%M'

const floatFormatString = (value) => {
  const absValue = Math.abs(value);

  if (absValue == 0)   return '.3~s';
  if (absValue < 0.01) return '.1e';
  if (absValue < 10)   return '.2~f';
  else                 return '.3~s';
}

const integerFormatString = (value) => Math.abs(value) < 100 ? '.0f' : '.3~s'

const percentageFormatString = (value) => {
  const absValue = Math.abs(value);

  if (absValue < 0.00000001) { // Pick a very small value that most likely indicate 0 + rounding error
    return '.0%';
  } else {
    return (absValue < 0.01) ? '.3~p' : '.3p';
  }
}

const formatStringsByType = {
  "currency": currencyFormatString,
  "date_date": dateFormatString,
  "date_time": datetimeFormatString,
  "float": floatFormatString,
  "integer": integerFormatString,
  "percentage": percentageFormatString
}

const withDecoration = (formattedValue) => formattedValue.replace(/G$/, "B").replace(/k$/, "K")

const numberFormatter = (rawValue, type) => {
  if (isNaNString(rawValue)) {
    return "";
  }

  const typedFormatStringFor = formatStringsByType[type] || formatStringsByType["integer"];
  const usingFormatString = typedFormatStringFor(rawValue);

  return withDecoration(d3.format(usingFormatString)(rawValue))
}

const dateFormatter = (rawValue, type) => {
  const typedFormatStringFor = formatStringsByType[type] || formatStringsByType["date_date"];
  const usingFormatString = typedFormatStringFor(rawValue);

  return withDecoration(d3.utcFormat(usingFormatString)(Date.parse(rawValue)))
}

const isDateType = (type) => type && type.includes("date_")

const formatterFor = (type) =>
  (rawValue) => isDateType(type) ? dateFormatter(rawValue, type) : numberFormatter(rawValue, type)

export default formatterFor;
