import { formattedDateTime } from '../utils/display';

class HoverDates {
  constructor(options) {
    this.entries = options.entries;
  }

  drawStartDates = () =>
    this.entries.append("div").attr("class", "timeline__entry-hover-date timeline__entry-hover-date--starts-at")
      .append("p").text((d) => formattedDateTime(d["starts_at"]))

  drawEndDates = () =>
    this.entries.append("div").attr("class", "timeline__entry-hover-date timeline__entry-hover-date--ends-at")
      .append("p").text((d) => formattedDateTime(d["ends_at"]))

  setHoverState = () => {
    this.entries.nodes().forEach((node) => {
      node.addEventListener("mouseenter", (e) => {
        e.currentTarget.querySelectorAll('div').forEach((node) => {
          node.classList.add('timeline__entry-hover-date--visible')
        })
      })
      node.addEventListener("mouseleave", (e) => {
        e.currentTarget.querySelectorAll('div').forEach((node) => {
          node.classList.remove('timeline__entry-hover-date--visible')
        })
      })
    })
  }

  draw = () => {
    this.drawStartDates();
    this.drawEndDates();
    this.setHoverState();
  }
}

export default HoverDates;
