import ApexCharts from "apexcharts";

import BaseWidget from './base_widget';

import LineChartOptions from 'apex/utils/chart_options/line_chart_options';
import WithWidgetOptions from 'apex/utils/chart_options/extenders/with_widget_options';

class LineChartWidget extends BaseWidget {
  constructor(attributes) {
    super(attributes);

    this.chart = null;
  }

  load = () => $.getJSON(this.attributes.dataUrl, this.updateChartData).fail(this.handleLoadFailure)

  handleLoadFailure = (xhr) => this.renderDataErrorMessage(xhr.responseJSON?.message || 'No data available')

  destroyChart = () => {
    this.chart && this.chart.destroy();
    this.chart = null;
    this.element.empty();
  }

  buildChart = (data) => {
    const chartOptions = new WithWidgetOptions(data, LineChartOptions).build()
    this.chart = new ApexCharts(this.element.get(0), chartOptions);
  }

  render = () => {
    this.chart && this.chart.render()
  }

  updateChartData = (data) => {
    this.destroyChart();

    if (Object.keys(data.lines)?.length) {
      this.buildChart(data);
      this.render();
    } else {
      this.renderDataErrorMessage(data.meta.error_message);
    }
  }
}

export default LineChartWidget;
