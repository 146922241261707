import jquery from 'jquery';
import moment from 'moment';
import ApexCharts from "apexcharts";
import select2 from "select2/dist/js/select2.full";

window.jquery = jquery;
window.jQuery = jquery;
window.$ = jquery;

window.moment = moment;

window.ApexCharts = ApexCharts;

select2();
