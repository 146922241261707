import AddTreatmentButton from "./add_treatment_button";

class TreatmentTable {
  constructor(el, maxTreatmentCount) {
    this.$el = $(el);
    this.addTreatmentButton = new AddTreatmentButton(this.$el.find(".add_fields"), maxTreatmentCount);

    this.$el.on("cocoon:after-insert cocoon:after-remove", () => this.update());
  }

  update() {
    const rows = this.$el.find(".treatment-fields").filter((_, el) => el.style.display !== "none");

    rows.each(this.updatePlaceholder);
    this.addTreatmentButton.setTreatmentCount(rows.length);
  }

  updatePlaceholder(idx, el) {
    const $el = $(el);
    const $nameInput = $el.find('input[name$="[name]"]');
    const defaultName = idx === 0 ? "Control" : `Treatment ${String(idx).padStart(2, '0')}`;

    $nameInput.attr("placeholder", defaultName);
  }
}

export default TreatmentTable;
