class ScrollManager {
  DEFAULT_LAYOUT = {
    mobileWidthBreakpoint: 768,
    scrollScale: 0.5,
    entryLeftOffset: 20
  }

  constructor(options) {
    this.root = options.root;
    this.scale = options.scale;
    this.entries = options.entries;
    this.rootWidth = options.rootWidth;
    this.layout = Object.assign({}, this.DEFAULT_LAYOUT, options.layout);
    this.startsAt = options.startsAt;
  }

  setChartScrollLimit = () =>
    this.root.node().addEventListener("scroll", (e) => {
      const isMobileLayout = window.innerWidth <= this.layout.mobileWidthBreakpoint
      const axisMargin = parseInt($(".timeline__x-axis").css("margin-left"))

      const currentScrollLeft = e.currentTarget.scrollLeft;
      const scaledScrollLimit =
        isMobileLayout ? (this.layout.minChartWidth - window.innerWidth) : (this.rootWidth * this.layout.scrollScale + axisMargin * 2);

      if (scaledScrollLimit < currentScrollLeft) {
        e.currentTarget.scrollLeft = scaledScrollLimit;
      }
    })

  setChartScrollPosition = () =>
    this.root.node().scrollLeft = this.scale(this.startsAt)

  setOffscreenEntryNamesToAdjustAfterScroll = () => {
    let afterScrollCb;
    const scrollStoppedInterval = 50;

    this.root.node().addEventListener("scroll", () => {
      window.clearTimeout(afterScrollCb);
      afterScrollCb = setTimeout(this.adjustOffscreenEntryNames, scrollStoppedInterval);
    });
  }

  adjustOffscreenEntryNames = () => {
    const fixedRootLeft = this.root.node().getBoundingClientRect().left;

    this.entries.entries.nodes().forEach((node) => {
      const textContainer = node.children.namedItem(`text-container--${node.dataset.key}`);

      if (textContainer === null) {
        return
      }

      const containerLeft = textContainer.getBoundingClientRect().left;
      const containerRight = textContainer.getBoundingClientRect().right;

      let updatedLeftOffset = this.layout.entryLeftOffset;

      // hide text for entries that are so far offscreen that only a few characters of text are visible
      textContainer.style.paddingRight = containerRight - fixedRootLeft < 50 ? "50px" : "inherit";
      node.style.paddingRight = containerRight - fixedRootLeft < 50 ? "50px" : "inherit";

      // add padding for entries are less far offscreen, so that the text is always visible in the window
      if (containerLeft < fixedRootLeft) {
        updatedLeftOffset += (fixedRootLeft - containerLeft)
      }

      textContainer.style.paddingLeft = `${updatedLeftOffset}px`
      node.style.paddingLeft = `${updatedLeftOffset}px`
    })
  }

  setScrollBehavior() {
    this.setChartScrollLimit();
    this.setChartScrollPosition();
    this.adjustOffscreenEntryNames();
    this.setOffscreenEntryNamesToAdjustAfterScroll();
  }
}

export default ScrollManager;
