import "mediaelement";
import imagePath from 'mediaelement/src/css/mejs-controls.svg'

$(document).on('turbo:load', function () {
  $('audio').each(function() {
    $(this).mediaelementplayer({
      features: ['playpause', 'current', 'progress', 'duration'],
      iconSprite: imagePath.replace('./', '/assets/'),
      stretching: $(this).data('responsiveness'),
      success: (mediaElement, originalNode, instance) => {
        const $wrapperElement = $(mediaElement).closest('.mejs__container').parent();

        $(document).one('turbo:before-cache', () => {
          if (!instance.paused) { instance.pause(); }

          mediaElement.remove();
          originalNode.remove();
          originalNode.removeAttribute('id');
          originalNode.removeAttribute('preload');
          $wrapperElement.html(originalNode);
        });
      }
    })
  })
})
