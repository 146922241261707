export default class UrlManager {
  constructor() {
    this.url = new URL(window.location.href);
    this.params = new URLSearchParams(this.url.search);
  }

  static getInstance() {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new UrlManager();

    return this.instance;
  }

  addOrModify(attribute, value) {
    this.params.set(attribute, value);
    this.updateHistory();
  }

  get(attribute) {
    return this.params.get(attribute);
  }

  delete(attribute) {
    this.params.delete(attribute);
    this.updateHistory();
  }

  updateHistory() {
    const newUrl = new URL(`${this.url.origin}${this.url.pathname}?${this.params.toString()}`);

    history.pushState({}, '', newUrl);
  }

}
