const PACIFIC_TIME = 'US/Pacific';

const FORMATTED_DATE_DEFAULTS = {
  weekday: 'short',
  month: 'short',
  day: 'numeric'
};

const FORMATTED_DATE_TIME_OPTIONS = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZone: PACIFIC_TIME
};

export const formattedDateTime = (date, options = {}) => {
  const dateOptions = { ...FORMATTED_DATE_TIME_OPTIONS, ...options };

  return date.toLocaleDateString("en-US", dateOptions);
};

export const formattedDate = (date, options = {}) => {
  const dateTimeOptions = { ...FORMATTED_DATE_DEFAULTS, ...options };
  return date.toLocaleDateString("en-US", dateTimeOptions);

}
