import ApexCharts from 'apexcharts';
import { merge } from 'lodash';

import LineChartOptions from 'apex/utils/chart_options/line_chart_options';

class ScrapesScoresChartComponent {
  static defaultOptions() {
    return LineChartOptions.defaultOptions();
  }

  constructor(element, options) {
    this.element = element;
    this.options = merge(this.constructor.defaultOptions(), options);
    this.chart = new ApexCharts(this.element, this.options);
  }

  render() {
    this.chart.render();
    this._bindDestroyEvent();
  }

  destroy(_e) {
    this.chart.destroy();
  }

  _bindDestroyEvent() {
    document.addEventListener('turbo:before-cache', this.destroy.bind(this));
  }
}

document.addEventListener('turbo:load', () => {
  const chartList = Array.from(document.querySelectorAll('[data-scrapes-scores-chart]'));

  chartList.forEach((el) => {
    const options = JSON.parse(el.dataset.scrapesScoresChart || '{}');

    (new ScrapesScoresChartComponent(el, options)).render();
  });
});

export default ScrapesScoresChartComponent;
