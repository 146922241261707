import Component from '../../store/lib/component.js';

export const DATA_SOURCE_SELECTOR = '#elc-data-sources';
export const DATA_SOURCE_DESCRIPTION_SELECTOR = '#elc-data-source-description';
export const URL_PARAMETER = 'data-source';

export default class DataSourceSelector extends Component {
  constructor(props = {}) {
    super({
      ...props,
      urlParameter: URL_PARAMETER
    });

    this.$dataSourceSelect = $(DATA_SOURCE_SELECTOR);
    this.$dataSourceDescription = $(DATA_SOURCE_DESCRIPTION_SELECTOR);

    this.store.events.subscribe('dataSourceSelected', () => { this.onDataSourceSelected(); });

    this.$dataSourceSelect.on('change', (e) => {
      if (!this.store.state.initializationMode) {
        this.store.events.publish('clearMeasures');
        this.store.events.publish('clearFilters');
      }

      this.changeDataSource($(e.target).val());

      if ($(e.target).val() != '') {
        this.dataStorage.addOrModify(this.urlParameter, $(e.target).val());
      }
    });

    const val = this.dataStorage.get(this.urlParameter);
    if (val != null) {
      this.store.commit('dataSource', val );
    }
  }

  initialize() {
    const val = this.store.state.dataSource;
    if (val != null) {
      this.$dataSourceSelect.val(val).trigger('change');
      this.changeDataSource(val);
    }
  }

  changeDataSource(payload) {
    this.store.events.publish('clearSamplesData');
    this.store.events.publish('resetResults');

    // Set data source value
    this.store.commit('dataSource', payload);

    this.loadMeasures();

    // Hide chart and samples data
    this.store.events.publish('destroyChart');
    this.store.events.publish('hideSamplesData');

    this.store.events.publish('dataSourceSelected');

    // Populate filters if we have a data source
    if (payload != null) {
      this.store.events.publish('populateFilters');
    }
  }

  loadMeasures() {
    const filteredMeasures = this.store.state.dataMeasures.find((element) => {
      return element.id == this.store.state.dataSource;
    });

    this.store.commit('measures', filteredMeasures ? filteredMeasures.measures : [] );
    this.store.events.publish('populateMeasures');
  }

  onDataSourceSelected() {
    const $elt = this.$dataSourceSelect.find('option:selected');

    this.$dataSourceDescription.html($elt.data('description') || '');
  }
}
