import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ab--doc-config"
export default class extends Controller {
  static targets = ["confidencePercentage", "memoryLength", "memoryStrategy"]
  static values = {
    memoryStrategy: String
  }

  memoryStrategyChanged({ target: { value } }) {
    this.memoryStrategyValue = value
  }

  memoryStrategyValueChanged() {
    this.memoryLengthTarget.disabled = true
    this.confidencePercentageTarget.disabled = true

    if (this.memoryStrategyValue === "auto_truncate") {
      this.memoryLengthTarget.disabled = false
    } else if (this.memoryStrategyValue === "fixed_window") {
      this.confidencePercentageTarget.disabled = false
    }
  }
}
