import BaseWidget from "./base_widget";
import DataTableOptionsBuilder from "brix/utils/data_table_options_builder";

const SIGNIFICANCE_COLUMN_INDEX = -1;

class ABExperimentBayesianReadoutWidget extends BaseWidget {
  load = () => {
    $.get(this.attributes.dataUrl, (html) => {
      this.initializeTable(html);
      this.registerSignificanceHandlers();
    });
  }

  initializeTable = (html) => {
    this.element.html(html);

    this.table = this.element
      .find('table')
      .css({ width: '100%' });

    this.dataTable = this.table
      .DataTable(this.initializeBuilder().call())
      .draw()
  }

  initializeBuilder = () => {

    // searching must always be true for the "significant only"
    // checkbox to work, but we don't want to show the field if
    // the user has disabled searching on the widget itself
    const dom = this.table.data('searching') ? 'ftpl' : 'tpl';
    this.table.data('searching', true);

    return new DataTableOptionsBuilder()
      .dom(dom)
      .columnDef({ visible: false, targets: SIGNIFICANCE_COLUMN_INDEX })
      .emptyTable(this.table.data('error-message'))
      .order(this.table.data('sort-columns'))
      .columnDef({ type: 'natural', targets: this.table.data('natural-sort-columns') });
  }

  registerSignificanceHandlers = () => {
    this.element.on('change', '.toggle-significance input', (e) => {
      $.rails.fire(e.target.closest('form.new_brix_ad_hoc_filter'), 'submit');
    });

    this.element.on('ajax:before', 'form.new_brix_ad_hoc_filter', () => {
      this.table.find('tbody').hide('fast');
    });

    this.element.on('ajax:success', 'form.new_brix_ad_hoc_filter', (e) => {
      const xhr = e.detail[2];
      this.initializeTable(xhr.response);
    });
  }
}

export default ABExperimentBayesianReadoutWidget;
