import Component from '../../../../utils/store/lib/component.js';
import { calculateRecommendedDays } from '../../../../utils/days_calculator';

export const SUBMIT_SELECTOR = '#elc__submit';

export default class SubmitSizing extends Component {
  constructor(props = {}) {
    super(props);

    $(SUBMIT_SELECTOR).on('click', (e) => {
      e.preventDefault();

      // Fill all hidden fields with value from the calculator state
      const data = this.transformData();
      data.forEach((value, key) => {
        $(`input[name="experiment[experiment_sizing_attributes][${key}]`).val(value);
      });

      e.target.classList.add('disabled');
      e.target.setAttribute('disabled', true);

      $('[name$=\'percent_of_traffic]\']').val(data.get('traffic_per_treatment'));

      props.store.events.publish('datesRefresh');
    });

    props.store.events.subscribe('stateChange', () => {
      $(SUBMIT_SELECTOR).removeClass('disabled').removeAttr('disabled');
    });

    props.store.events.subscribe('submitSizing', () => {
      $(SUBMIT_SELECTOR).trigger('click');
    });
  }

  transformData() {
    let data = new Map();

    data.set('sequential', this.store.state.sequential);
    data.set('calculated_days', calculateRecommendedDays(this.store.state.calculatedDays, this.store.state.sequential));
    data.set('period_type', this.store.state.dateType);
    data.set('date_start', this.store.state.dateStart);
    data.set('date_end', this.store.state.dateEnd);

    // Base ones
    data.set('measure_id', this.store.state.measure);
    data.set('brix_data_source_id', this.store.state.dataSource);
    data.set('test_type', this.store.state.testType);
    data.set('expected_lift', this.store.state.expectedLift);
    data.set('traffic_per_treatment', this.store.state.trafficPerTreatment);
    data.set('impact_percentage', this.store.state.impactPercentage);
    data.set('filters', JSON.stringify(this.store.state.filters));
    data.set('delta_method_factor', this.store.state.deltaMethodFactor);

    return data;
  }
}
