function updateFilterTypes(filterType) {
  $('#experiment-plan-result-filters-form')
    .find('[data-filter-type]').prop('disabled', true).end()
    .find(`[data-filter-type="${filterType}"]`).prop('disabled', false)
}

$(document).on('change', '#experiment-plan-result-filters-form .filter-type-radio', (e) => {
  const filterType = $(e.target).val()

  updateFilterTypes(filterType)
})

$(document).on('show.bs.modal', () => {
  const form = $('#experiment-plan-result-filters-form')

  if (form.length) {
    const filterType = form.find('.filter-type-radio:checked').val()
    updateFilterTypes(filterType)
  }
})

$(document).on('change', '#experiment-plan-result-filters-form .widget-filter__select', (e) => {
  const $select = $(e.target)
  const urlTemplate = $select.data('url-template')
  const url = urlTemplate.replace(/__id__/, $select.val())
  const $child = $select.siblings('.widget-filter-values__select')

  $.getJSON(url).done((records) => {
    $child.empty()

    for (const record of records) {
      const $option = $("<option>").attr("value", record.value).text(record.text)

      $child.append($option)
    }
  })
})
