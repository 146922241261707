import "datatables.net-bs5";
import "datatables.net-rowgroup-bs5";
import "datatables.net-plugins/sorting/natural";
import "daterangepicker";

import "./patches/cocoon";
import "./patches/jquery-ui-sortable";
import "./patches/select2-jquery3.6"; // See file for patch explanation

import "./utils";
import "./ab";
import "./apps";
import "./brix";
import "./comparisons";
import "./components";
import "./etl";
import "./evaluations";
import "./endpoint_diff";
import "./favorites";
import "./input_sets";
import "./metrics";
import "./mtkit";
import "./nested_select";
import "./training";
import "./layout";
import "./lpa";
import "./prune";
import "./sessions";
import "./tabular";
import "./turbo_stream_actions"
