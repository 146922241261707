import Dashboard from "./dashboard";

const dashboardShouldBeLoaded = () => {
  const pageContent = $('html');

  const isDashboardShowOrPresentationVisit =
    pageContent.find("body.dashboards.show").length || pageContent.find("body.dashboards.presentation").length;

  const userHasDashboardViewAccess = pageContent.find("div.dashboard").length;

  return isDashboardShowOrPresentationVisit && userHasDashboardViewAccess;
}

$(document).on("turbo:load", () => {
  $(function() {
    if (dashboardShouldBeLoaded()) {
      const dashboard = new Dashboard($("#dashboard")).render();

      $(document).on("brix:portlets:updated", (_e, id) => dashboard.refreshPortlet(id));
      $(document).on("brix:portlets:created", (_e, { content, width, height, column, row }) => dashboard.addPortlet(content, width, height, column, row));

      $(document).on("turbo:before-cache", () => {
        $(document).off("brix:portlets:updated");
        $(document).off("brix:portlets:created");
      })
    }
  });
});
