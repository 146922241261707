import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"

// Connects to data-controller="bs--tooltip"
export default class extends Controller {
  static values = { options: Object }

  connect() {
    this.tooltip = new Tooltip(this.element, this.optionsValue)
  }

  disconnect() {
    this.tooltip.dispose()
    this.tooltip = null
  }
}
