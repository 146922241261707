import { minMax } from "utils/array"

class RadarChartDataNormalizer {
  constructor(chartData) {
    this.series = chartData.series

    this.rawValuesByMeasure = this._rawValuesByMeasure()
    this.normalizedValuesByDimension = this._normalizedValuesByDimension()
  }

  get rangesByMeasure()  { return this._rangesByMeasure() }
  get normalizedSeries() { return this._normalizedSeries() }

  _normalizedSeries = () =>
    this.series.map((dimension, forDimension) =>
      Object.assign({}, dimension, { data: this.normalizedValuesByDimension[forDimension] })
    )

  _rangesByMeasure = () => this.rawValuesByMeasure.map(this._minAndMax)

  _rawValuesByMeasure = () => this.rawValuesByMeasure || this._transpose(this.series.map((d) => d.data))
  _normalizedValuesByDimension = () =>
    this.normalizedValuesByDimension || this._transpose(this.rawValuesByMeasure.map(this._normalizeValues))

  _normalizeValues = (values) =>
    values.map((value) => this._normalizeValue(value, ...this._minAndMax(values)))

  _normalizeValue = (value, min, max) => (value - min) / (max - min)

  _minAndMax = (array) => minMax(array, 0, 0)
  _transpose = (array) => array[0].map((_, i) => array.map(row => row[i]))
}

export default RadarChartDataNormalizer;
