export default class PlanExperimentDataStorage {

  constructor() {
    this.data = new Map();
  }

  addOrModify(attribute, value) {
    this.data.set(attribute, value);
  }

  get(attribute) {
    return this.data.get(attribute);
  }

  delete(attribute) {
    this.data.delete(attribute);
  }

  all() {
    return this.data;
  }
}
