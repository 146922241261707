import PublishSubscribe from './lib/publish_subscribe';

class Store {

  constructor(params) {
    let self = this;

    self.actions = {};
    self.mutations = {};
    self.state = {};

    self.events = new PublishSubscribe();

    if(params.hasOwnProperty('actions')) {
      self.actions = params.actions;
    }

    if(params.hasOwnProperty('mutations')) {
      self.mutations = params.mutations;
    }

    self.state = new Proxy((params.initialState || {}), {
      set: function(state, key, value) {
        state[key] = value;
        console.log(`stateChange: ${key}: ${value}`);

        self.events.publish('stateChange', self.state);
        return true;
      }
    });
  }

  dispatch(actionKey, payload) {
    let self = this;

    if(typeof self.actions[actionKey] !== 'function') {
      console.error(`Action "${actionKey} doesn't exist.`);
      return false;
    }

    // Create a console group which will contain the logs from our Proxy etc
    console.groupCollapsed(`ACTION: ${actionKey}`);

    self.actions[actionKey](self, payload);

    // Close our console group to keep things nice and neat
    console.groupEnd();

    return true;
  }

  commit(mutationKey, payload) {
    let self = this;

    let keyobject = {}
    keyobject[mutationKey] = payload

    console.groupCollapsed(`Mutation: ${mutationKey}`);

    let newState = {...self.state, ...keyobject }
    self.state = Object.assign(self.state, newState);

    console.groupEnd();

    return true;
  }
}


export default Store;
