class PollIterationPercentScraped {
  constructor (element) {
    this.$element = $(element)
    this.url = this.$element.data('comparison-iteration-url')
  }

  startPolling () {
    this.timer = setInterval(this._poll.bind(this), 1000)
    $(document).one('turbo:before-cache', this._clearTimer.bind(this))
  }

  _clearTimer () {
    clearInterval(this.timer)
  }

  _poll () {
    $.getJSON(this.url).done(({ data: { percentScraped, complete } }) => {
      if (complete) this._clearTimer()

      this.$element.find('[data-attr="percent-scraped"]').text(percentScraped);
    })
  }
}

document.addEventListener('turbo:load', () => {
  $('[data-complete=false][data-comparison-iteration-url]').each(function() {
    new PollIterationPercentScraped(this).startPolling();
  });
})

export default PollIterationPercentScraped;
