import { BucketAssignment, Seed, TreatmentFinder } from '@amp/baseline-ab-web';

class Filters {
  constructor() {
    this.$seedTypeSelector = $('#seed_type_id');
    this.$identifierLabel = $('#treatment-assignment-filters__label');
  }

  initialize() {
    this.bind();
    this.refreshLabel();
  }

  bind() {
    this.$seedTypeSelector.on('change', () => { this.refreshLabel(); });
  }

  refreshLabel() {
    const $currentOption = this.$seedTypeSelector.find('option:selected');
    this.setLabel($currentOption.data('key'));
  }

  setLabel(label) {
    if (label == null || label.trim().length === 0) {
      this.$identifierLabel.html('Identifier');
    } else {
      this.$identifierLabel.html(`Identifier (${label})`);
    }
  }
}

class Assignment {
  constructor(area, context) {
    this.area = area;
    this.treatmentFinder = context.treatmentFinder;
    this.identifier = context.identifier;
    this.storefrontKey = context.storefrontKey;
    this.treatmentMetadata = context.treatmentMetadata;
  }

  updateTreatmentAssignment($el) {
    return this.treatmentFinder.getTreatmentForArea(this.area.id, this.identifier, new Date(), this.storefrontKey)
      .then((treatment) => {
        if (!treatment) { return; }

        const { key, name, experimentName, url } = this.treatmentMetadata[treatment.id];
        const linkText = `${experimentName} — ${name} (${key})`;
        const link = $('<a>').attr('href', url).text(linkText);

        $el.html(link);
      });
  }

  updateBucketAssignment($el) {
    const seed = new Seed(this.area, this.identifier);
    const bucketAssignment = new BucketAssignment(seed);

    bucketAssignment.call().then((bucket) => $el.text(bucket));
  }
}

class Context {
  constructor(config, identifier, storefrontKey) {
    const fetcher = () => Promise.resolve(config.treatmentProviderConfiguration);

    this.identifier = identifier;
    this.storefrontKey = storefrontKey;
    this.treatmentFinder = new TreatmentFinder(fetcher);
    this.treatmentMetadata = config.treatmentMetadata;
  }
}

function checkForTreatmentAssignments() {
  if ($('#treatment-assignments').length) {
    buildContext();
    (new Filters()).initialize();
  }
}

function buildContext() {
  const url = $('#treatment-assignments').data('configuration-url');
  const identifier = $('#identifier').val();
  const storefrontKey = $('#storefront_key').val();

  return $.getJSON(url).done((config) => {
    const context = new Context(config, identifier, storefrontKey);
    updateAllAssignments(context);
  });
}

function updateAllAssignments(context) {
  $('#treatment-assignments .area').each((_, el) => updateAssignmentsForArea(el, context));
}

function updateAssignmentsForArea(el, context) {
  const $el = $(el);

  const area = {
    id: $el.data('areaKey'),
    salt: $el.data('areaSalt'),
  };

  const assignment = new Assignment(area, context);
  assignment.updateTreatmentAssignment($el.find('.treatment-assignment'));
  assignment.updateBucketAssignment($el.find('.bucket-assignment'));
}

$(document).on('turbo:load', checkForTreatmentAssignments);
