import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).sortable({
      handle: '.drag-handle',
      items: 'tbody > tr',
      axis: 'y',
      stop: function(_e, ui) {
        const item = $(ui.item)
        const position = item.parent().children().index(item);

        $.ajax({
          url: item.data('updateUrl'),
          method: 'patch',
          data: {
            searchable_page: { position }
          }
        });
      },
    });
  }

  disconnect() {
    $(this.element).sortable('destroy')
  }
}
