import { Tooltip } from 'bootstrap'

const allowList = Tooltip.Default.allowList;
const matchAllAttributesRegex = /.*/;
const additionalAllowList = [
  'button',
  'dd',
  'dl',
  'dt',
  'form',
  'input',
  'label',
  'option',
  'select',
  'textarea',
  'trix-editor'
];

additionalAllowList.forEach((el) => allowList[el] = []);
allowList['*'].push(matchAllAttributesRegex);

const initBootstrap = (target) => {
  $(function () {
    $(target).find('[data-bs-toggle="tooltip"]').tooltip();
    $(target).find('[data-bs-toggle="popover"]').popover();
  })
}

$(document).on('turbo:load show.bs.modal popover:load cocoon:after-insert widget:loaded', (e) => initBootstrap(e.target));

$(document).on('turbo:before-visit', () => {
  $('[data-bs-toggle="tooltip"]').tooltip('hide');
});
