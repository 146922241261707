class OverrideJudgment {
  static defaultOptions = {
    overrideAnswerSelect: "select[id^='override-answer']",
    overrideAnswerTextarea: "textarea[id^='override-answer'], textarea[id^='override-comment']",
    emptyOverrideAnswerCheckbox: "input[id^='empty-override-answer']"
  }

  constructor (tableRow) {
    this.$tableRow = $(tableRow)
    this.options = this.constructor.defaultOptions
  }

  create () {
    this.$tableRow.on('change', this.options.onOverrideAnswerSelect, this._onOverrideAnswerSelectChange.bind(this))
    this.$tableRow.on('blur', this.options.overrideAnswerTextarea, this._onOverrideAnswerTextareaBlur.bind(this))
    this.$tableRow.on('click', this.options.emptyOverrideAnswerCheckbox, this._onEmptyOverrideAnswerCheckboxClick.bind(this))
  }

  _onOverrideAnswerSelectChange (e) {
    const $target = $(e.target)
    const data = {
      [`${$target.attr('name')}`]: $target.val()
    }

    this._overrideJudgment($target, data)
  }


  _onOverrideAnswerTextareaBlur (e) {
    const $target = $(e.target)
    const $pairedCheckbox = $($target.data("paired-checkbox"))

    const data = {
      [`${$target.attr('name')}`]: $target.val(),
      [`${$pairedCheckbox.attr('name')}`]: false,
    }

    this._overrideJudgment($target, data)
  }

  _onEmptyOverrideAnswerCheckboxClick (e) {
    const $target = $(e.target)
    // get value from data value, or else it only will work when it's marked as true
    // https://api.rubyonrails.org/classes/ActionView/Helpers/FormBuilder.html#method-i-check_box
    const $value = !$target.data("value")
    const $pairedTextarea = $($target.data("paired-textarea"))
    const data = {
      [`${$target.attr('name')}`]: $value,
      [`${$pairedTextarea.attr('name')}`]: '',
    }

    this._strikeOriginalAnswer($target)
    this._toggleDisabledTextarea($pairedTextarea)
    this._overrideJudgment($target, data)
  }

  _overrideJudgment ($target, data) {
    const $pairedInput = $($target.data("paired-input"))
    const $reloadedTarget = () => $("#" + $target.attr("id"))

    $.ajax({
      url: $target.attr('rel'),
      method: 'PATCH',
      data: $.extend({[`${$pairedInput.attr('name')}`]: $pairedInput.val()},
                     data),
      success: () => {
        $reloadedTarget().siblings(".confirmation-icon").flashConfirmationIcon();
      },
      error: () => {
        alert('There was a problem saving your override.')
      }
    })
  }

  _strikeOriginalAnswer ($target) {
    const $originalAnswer = $($target.data('paired-answer'))
    $originalAnswer.toggleClass('struck');
  }

  _toggleDisabledTextarea ($textarea) {
    const $textArea = $($textarea)

    $textArea[0].value = "";
    $textArea[0].disabled = !$textArea[0].disabled
  }
}

$(document).on('show.bs.modal', () => {
  const $fieldList = $(`[data-override-judgment]`)

  $.map($fieldList, (el) => {
    return (new OverrideJudgment(el)).create()
  })
})

export default OverrideJudgment
