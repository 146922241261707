import BaseWidget from "./base_widget";

class RichTextWidget extends BaseWidget {
  load = () => $.get(this.attributes.dataUrl, this.redraw).fail(this.handleLoadFailure)

  redraw = this.setHtml

  handleLoadFailure = (xhr) => this.setHtml(xhr.statusText)
}

export default RichTextWidget;
