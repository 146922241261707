import { merge } from 'lodash';

import BaseOptions from './base_options';
import formatterFor from '../formatter_for';

class VerticalBarChartOptions extends BaseOptions {
  static defaultOptions() {
    return merge(super.defaultOptions(), {
      chart: {
        type: 'bar'
      },
      grid: {
        padding: {
          left: 30,
          bottom: 30
        }
      },
      xaxis: {
        labels: {
          trim: true,
          formatter: (value, _timestamp, _opts) => {
            const maxCharPerLine = 15;
            const linePattern = new RegExp(`.{1,${maxCharPerLine}}(?:\\s|$)`, 'g');
            const label = String(value);

            if (label.length <= maxCharPerLine) return label;

            const arr = label.match(linePattern);

            return [
              arr.shift().trim(),
              arr.join('')
            ];
          }
        }
      },
      fill: {
        opacity: 1
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
      },
      plotOptions: {
        bar: {
          columnWidth: '90%',
          dataLabels: {
            position: 'top'
          }
        },
      },
      states: {
        hover: {
          filter: 'none'
        }
      },
      stroke: {
        show: true,
        width: 4,
        colors: ['transparent']
      },
      tooltip: {
        enabled: false
      }
    });
  }

  constructor(chartData) {
    super(chartData);

    this.dataDependentOptions = this.setDataDependentOptions();
  }

  setDataDependentOptions = () =>
    ({
      dataLabels: {
        formatter: formatterFor(this.chartData.meta.data_labels_format)
      },
      title: {
        text: this.chartData.meta.title_text
      },
      xaxis: {
        categories: this.chartData.meta.dimension_categories,
        title: {
          text: this.chartData.meta.dimension_title_text
        },
        labels: {
          minHeight: 150
        }
      },
      yaxis: {
        title: {
          text: this.chartData.meta.measure_title_text
        },
        labels: {
          formatter: formatterFor(this.chartData.meta.measure_labels_format)
        }
      },
    })
}

export default VerticalBarChartOptions;
