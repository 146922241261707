const setIconVisibility = () => {
  const $input = $('.widget__title-field-input').first();
  const $insertIcon = $('.widget__insert-title-placeholder-icon').first();
  const $clearIcon = $('.widget__clear-title-input-icon').first();

  if ($input.val()) {
    $insertIcon.hide();
    $clearIcon.show();
  } else {
    $insertIcon.show();
    $clearIcon.hide();
  }
}

const insertWidgetTitlePlaceholder = () => {
  const $input = $('.widget__title-field-input').first();
  const placeholderText = $input.attr('placeholder');

  $input.val(placeholderText);
  $input.focus();

  setIconVisibility();
}

const clearWidgetTitleInput = () => {
  const $input = $('.widget__title-field-input').first();
  $input.val('');

  setIconVisibility();
}

$(document).on('popover:load', setIconVisibility)
$(document).on('click', '.widget__insert-title-placeholder-icon', insertWidgetTitlePlaceholder);
$(document).on('click', '.widget__clear-title-input-icon', clearWidgetTitleInput);

