// handle selecting a data source
$(document).on('click', '#interesting-slice-data-sources a', function(e) {
  toggleSelectedDataSource(e.target);
  hideDataSourceMessages();
});

function toggleSelectedDataSource(el) {
  $(el).siblings().removeClass('active').end().toggleClass('active');
}

function hideDataSourceMessages() {
  $('.hide-when-data-source-selected').hide();
}

// clear the reusable interesting slices before a search
$(document).on('ajax:before', '#search-form', function() {
  $('#reusable-interesting-slices').empty();
});

// render the search form and trigger a search
$(document).on('ajax:complete', '#interesting-slice-data-sources', function(e) {
  const [{ responseText }] = e.detail;

  e.stopPropagation();
  $('#search-form')
    .html(responseText)
    .find('form')[0]
    .dispatchEvent(new Event('submit', { bubbles: true }));
});


// render reusable interesting slices when paginating
$(document).on('ajax:complete', '#reusable-interesting-slices', function(e) {
  const [{ responseText }] = e.detail;

  e.stopPropagation();
  $(e.currentTarget).html(responseText);
});


// hide the add/remove button when it is clicked
$(document).on('ajax:before', '.reusable-interesting-slice', function(e) {
  e.stopPropagation();
  $(e.target).addClass('invisible');
});

// re-render the reusable interesting slice
$(document).on('ajax:complete', '.reusable-interesting-slice', function(e) {
  const [{ responseText }] = e.detail;

  e.stopPropagation();
  $(e.currentTarget).replaceWith(responseText);
});
