class PollStatusWidget {
  constructor (element, url) {
    this.$element = $(element)
    this.url = url
  }

  startPolling () {
    this.timer = setInterval(this._poll.bind(this), 3000)
    $(document).one('turbo:before-cache', this._clearTimer.bind(this))
  }

  _clearTimer () {
    clearInterval(this.timer)
  }

  _poll () {
    const divElement = this.$element
    $.ajax(this.url).done(function( html ) {
      $(divElement).html(html)
    });
  }
}

document.addEventListener('turbo:load', () => {
  $('#endpoint-diff-status-widget, #scrape-status-widget').each(function() {
    const url = $(this).data("widget-status-url")
    new PollStatusWidget(this, url).startPolling();
  });
})

export default PollStatusWidget;
