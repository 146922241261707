import Timeline from './timeline';
import Timeframe from './components/timeframe';
import AvailableTraffic from './components/available_traffic';
import { clean } from './utils/data';

function drawChart(data, timelineObject) {
  const chart = new Timeline({
    rootObject: timelineObject,
    data: clean(data)
  });

  chart.draw();
}

async function refreshChart(timelineObject, formObject) {
  const url = $(timelineObject).data('url');
  const params = $(formObject).serialize();

  await $.getJSON(url, params, (data) => {
    drawChart(data, timelineObject)
  });
}


function initializeTimeline(timelineObjects) {
  if (timelineObjects.length) {
    for (let timelineObject of timelineObjects) {
      let formObject = $(timelineObject).closest('.timeline__container').find('.experiment-timeline-form')
      if (formObject.length == 0) {
        formObject = $('.experiment-timeline-form').first();
      }

      refreshChart(timelineObject, formObject);
    }
  }
}

function hookSubmitButton(target) {
  target.on('submit', async (e) => {
    e.preventDefault();

    let timelineObject;
    if (e.target.closest(".timeline__container")) {
      timelineObject = e.target.closest(".timeline__container").querySelector('.timeline__root');
    } else {
      timelineObject = document.querySelector('.timeline__root');
    }

    await refreshChart(timelineObject, e.target);

    $(document).find('input[type=submit][form^=experiment-timeline-form]').prop('disabled', false)
  })
}

function enableTimeframeOnExperimentForm() {
  const timeframe = new Timeframe($('form.ab-experiment-form'))
  const availableTraffic = new AvailableTraffic($('#available-traffic'))

  $(timeframe).on('change', (e, timeframe) => availableTraffic.refresh(timeframe));
}

$(document).on('turbo:load', function (event) {
  let formObject = $(event.target).find('.experiment-timeline-form').first();
  if (formObject.length == 0) {
    formObject = $('.experiment-timeline-form').first();
  }

  hookSubmitButton(formObject);
  initializeTimeline(event.target.querySelectorAll(".timeline__root"))
  enableTimeframeOnExperimentForm()
});

$(document).on('turbo:after-stream-render', function (event) {
  const fragment = document.getElementById(event.detail.newStream.target);
  let formObject = $(fragment).find('.experiment-timeline-form').first();
  if (formObject.length == 0) {
    formObject = $('.experiment-timeline-form').first();
  }

  hookSubmitButton(formObject);
  initializeTimeline(fragment.querySelectorAll('.timeline__root'))
});
