import Component from '../../../../utils/store/lib/component.js'

export const STARTS_AT_COMPONENT = '#experiment_starts_at';
export const ENDS_AT_COMPONENT = '#experiment_ends_at';
export const AREA_COMPONENT = '#experiment_area_id';

export default class DatesRefresh extends Component {
  constructor(props = {}) {
    super(props);

    props.store.events.subscribe('datesRefresh', (data) => {
      this.datesRefresh(data);
    });

    $(STARTS_AT_COMPONENT).closest('.datetime-picker-input').on('change.td', (e) => {
      if (typeof e.oldDate !== 'undefined' && e.oldDate !== null) { // Prevents a double call on datetimepickers init
        this.datesRefresh({ include_starts_at: true });
      }
    });

    $(AREA_COMPONENT).on('change', (e) => {
      this.datesRefresh({ include_starts_at: false });
      props.store.events.publish('submitSizing');
    });
  }

  datesRefresh({ include_starts_at }) {
    const wrapper = $("#experiment-length-calculator-wrapper");
    const requestUrl = wrapper.data('dates-calculation-url');
    const trafficPerTreatment = Number($('input[name="experiment[experiment_sizing_attributes][traffic_per_treatment]').val());

    // Number of treatments is explicit for New, and for edit we count fields
    const numberOfTreatments = $('#number-of-treatments').length ? $('#number-of-treatments').val() : $('.treatment-fields').length;

    if (trafficPerTreatment * numberOfTreatments > 100) {
      $('#experiment_starts_at').val(null);
      $('#experiment_ends_at').val(null);
      $('#experiment_aggregation_starts_on').val(null);
      $('#experiment_aggregation_ends_on').val(null);

      $('#experiment-submit-button').prop("disabled", true);
      $('#plans-sizing-traffic-required').html('Error: Traffic needed is more than 100%');
      $('#plans-sizing-calculated-days').html('-');
      $('#plans-sizing-closest-date').html('-');
      $('#plans-experiment-sizing-table').show();
    } else {
      $('#experiment-submit-button').prop("disabled", false);
      const data = {
        // If it's hidden it means it should be recalculated every time (new experiment)
        area_id: $('#experiment_area_id').val(),
        calculated_days: $('input[name="experiment[experiment_sizing_attributes][calculated_days]').val(),
        traffic_per_treatment: trafficPerTreatment,
        number_of_treatments: numberOfTreatments,
      }

      // When changing start date manually, starts_at should be included on the dates calculations
      if (include_starts_at) {
        data['starts_at'] = $('#experiment_starts_at:not([type=hidden])').val();
      }

      $.post(requestUrl, data, 'json').done((response) => {
        $('#experiment_starts_at').val(response.formatted_starts_at);
        $('#experiment_ends_at').val(response.formatted_ends_at);

        $('#experiment_starts_at, #experiment_ends_at').each((_, elt) => {
          elt.dispatchEvent(new InputEvent('input'))
        })

        $('#experiment_aggregation_starts_on').val(response.formatted_aggregation_starts_on);
        $('#experiment_aggregation_ends_on').val(response.formatted_aggregation_ends_on);

        $('#plans-sizing-traffic-required').html(response.traffic_required);
        $('#plans-sizing-calculated-days').html(response.calculated_days);
        $('#plans-sizing-closest-date').html(response.initial_date_with_available_traffic);
        $('#plans-experiment-sizing-table').show();
      }).fail(() => {
        console.log('Server Error')
      });
    }
  }

}
