const isOverflowing = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
}

const trimmedText = (el) => el.textContent?.trim()

const applyTooltipToOverflowingTableColumns = () => {
  $(function() {
    $('td.truncate-oversize-column, .text-truncate, .text-truncate-multi-line').each((_i, el) => {
      if (isOverflowing(el) && trimmedText(el)) {
        $(el).tooltip({ title: trimmedText(el), boundary: 'viewport' })
      }
    })
  });
}

$(document).on('turbo:load', applyTooltipToOverflowingTableColumns);
$(window).on('resize', applyTooltipToOverflowingTableColumns)
