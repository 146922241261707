import { merge, camelCase, mapKeys } from 'lodash'

class ConnectedField {
  static defaultOptions () {
    return {
      dataAttrName: 'connected-field'
    }
  }

  constructor (element, options) {
    this.$element = $(element)
    this.options = merge(this.constructor.defaultOptions(), mapKeys(options, (_v, k) => camelCase(k)))
  }

  get value () {
    switch (this.$element.attr('type')) {
      case 'checkbox':
      case 'radio':
        return this.$element.is(':checked') ? this.$element.val() : null
      default:
        return this.$element.val()
    }
  }

  create () {
    this.$element.on('change', this._onInputChangeEvent.bind(this))
    this._toggle()

    return this
  }

  _onInputChangeEvent (_e) {
    this._toggle()
  }

  _toggle () {
    const $fieldList = $(`[data-${this.options.dataAttrName}="${this.options.target}"]`)
    const value = this.value
    const visibleOnValues = [].concat(this.options.visibleOn)

    $.map($fieldList, (el) => {
      const $el = $(el)

      if (visibleOnValues.includes(value)) {
        $el.show()
      } else {
        $el.hide()
      }
    })
  }
}

document.addEventListener('turbo:load', () => {
  const dataAttrName = 'connected-field-for'
  const $fieldList = $(`[data-${dataAttrName}]`)

  $.map($fieldList, (el) => {
    const options = $(el).data(dataAttrName)

    return (new ConnectedField(el, options)).create()
  })
})

export default ConnectedField
