import formatterFor from 'apex/utils/formatter_for';
import annotationsFor from 'apex/utils/annotations_for';
import LineChartOptions from './line_chart_options';

class LineChartDashboardTemplateOptions extends LineChartOptions {
  constructor(chartData) {
    super(chartData);
    this.dataDependentOptions = this.setDataDependentOptions();
  }

  setDataDependentOptions = () =>
  ({
    annotations: {
      xaxis: annotationsFor(this.chartData.meta.annotations)
    },
    chart: {
      events: {
        click: this.legendClickHandler.call,
        mounted: (chart) => {
          const titleElement = chart.el.querySelector('.apexcharts-title-text');
          if (titleElement && this.chartData.meta.description) {
            titleElement.setAttribute('data-controller', 'bs--tooltip');
            titleElement.setAttribute('title', this.chartData.meta.description);
            titleElement.setAttribute('data-bs--tooltip-options-value', JSON.stringify({ html: true }));
          }
        }
      },
      height: '275',
    },
    colors: this.chartData.meta.colors,
    series: this.normalizer.timeSortedSeries,
    title: {
      text: this.chartData.meta.title,
      align: 'left',
      style: {
        fontSize: '13px',
        fontWeight: 'normal',
        color: 'var(--bs-secondary)',
      }
    },
    tooltip: {
      custom: this.tooltip.render
    },
    xaxis: {
      title: {
        show: false
      },
    },
    yaxis: {
      labels: {
        formatter: formatterFor(this.chartData.meta.format),
        offsetX: 0
      },
      title: {
        show: false
      },
      min: this.normalizer.yRange.min,
      max: this.normalizer.yRange.max
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: 20
    }
  })
}

export default LineChartDashboardTemplateOptions;
