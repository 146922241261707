import BaseWidget from "./base_widget";
import DataTableOptionsBuilder from "brix/utils/data_table_options_builder";

class TabularReportWidget extends BaseWidget {
  constructor(attributes) {
    super(attributes);
    this.initializeEventHandlers();
  }

  load = () => $.get(this.attributes.dataUrl, this.initializeTable)

  initializeTable = (html) => {
    this.setHtml(html);

    const $table = this.element.find("table").css({ width: "100%" });

    const builder = new DataTableOptionsBuilder()
      .dom("ftpl")
      .emptyTable($table.data("error-message"))
      .order($table.data("sort-columns"))
      .columnDef({
        type: "natural",
        targets: $table.data("natural-sort-columns")
      })

    $table.DataTable(builder.call()).draw();
  }

  initializeEventHandlers = () => {
    this.element.on("keyup", ".search-field input", (e) => {
      if (e.key == "Enter") {
        $.rails.fire(e.target.closest("form.new_brix_ad_hoc_filter"), "submit");
      }
    });

    this.element.on("ajax:send", "form.new_brix_ad_hoc_filter", () => this.loadingIcon.show());

    this.element.on("ajax:success", "form.new_brix_ad_hoc_filter", (e) => {
      const xhr = e.detail[2];

      this.initializeTable(xhr.response);
      this.loadingIcon.hide();
    });
  }
}

export default TabularReportWidget;
