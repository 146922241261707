import Grid from "../portlets/grid";

class Dashboard {
  constructor($el) {
    this.$el = $el;
    this.grid = new Grid(this.$el.find(".dashboard__grid"), this);
  }

  render = () => {
    this._setConfigurationIconTooltipText();
    this._registerConfigurationIconClickHandler();
    this._registerNewWidgetModalShowHandler();

    this.grid.disable();

    return this;
  };

  addPortlet = (...args) => this.grid.addGridItem(...args)

  refreshPortlet = (...args) => this.grid.refreshGridItem(...args)

  _setConfigurationIconTooltipText = () =>
    $("#configure-dashboard").tooltip({
      title: this.$el.hasClass("editable") ? "Configure this Dashboard" : "View this Dashboard",
    });

  _registerConfigurationIconClickHandler = () =>
    $(document).on("click", "#configure-dashboard", this._toggleConfigurationMode);

  _registerNewWidgetModalShowHandler = () =>
    $(document).on("show.bs.modal", () => {
      this.grid.disable();
    });

  _toggleConfigurationMode = () => {
    this.$el.toggleClass("configurable");

    this._isConfigurable() ? this.grid.enable() : this.grid.disable();
  };

  _isConfigurable = () => this.$el.hasClass("configurable") && this.$el.hasClass("editable");
}

export default Dashboard;
