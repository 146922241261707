function toggleDateRangeSelectors(el) {
  const show = !$(el).val();
  $(el).closest('.date-filters').find('.date-range-selectors').toggle(show);
};

function updateSamplePeriod(select) {
  const $select = $(select),
        sample = $select.find(':selected').data('sample');

  $select
    .siblings('.sample-period')
    .text(sample)
    .toggle(!!sample);
};

$(document).on('change', '.date-period-select', (e) => {
  toggleDateRangeSelectors(e.target);
  updateSamplePeriod(e.target);
});

$(document).on('popover:load show.bs.modal', () => {
  $('.date-period-select').each((_, el) => {
    toggleDateRangeSelectors(el);
    updateSamplePeriod(el);
  });
});
