import ABExperimentTabularReadoutWidget from "./ab_experiment_tabular_readout_widget";

const MEASURE_COLUMN_INDEX = 0;

class ABExperimentMeasuresReadoutWidget extends ABExperimentTabularReadoutWidget {
  initializeBuilder() {
    if (this.$table.data("group-rows")) {
      return this.initializeGroupedBuilder(super.initializeBuilder());
    } else {
      return this.initializeUngroupedBuilder(super.initializeBuilder());
    }
  }

  initializeGroupedBuilder(builder) {
    return builder
      .default({
        rowGroup: {
          dataSrc: (data) => data[MEASURE_COLUMN_INDEX]["display"]
        }
      })
      .default({
        orderFixed: [MEASURE_COLUMN_INDEX, "asc"]
      })
      .columnDef({
        visible: false,
        targets: MEASURE_COLUMN_INDEX
      });
  }

  initializeUngroupedBuilder(builder) {
    return builder.columnDef({
      width: "20%",
      targets: MEASURE_COLUMN_INDEX
    });
  }
}

export default ABExperimentMeasuresReadoutWidget;
