import Component from '../../store/lib/component.js';
import formatterFor from '../../../apex/utils/formatter_for';

export const SAMPLES_DATA_CONTAINER = '#elc-samples-data-container';
export const NUMERATOR_SELECTOR     = '#elc-numerator';
export const DENOMINATOR_SELECTOR   = '#elc-denominator';
export const RATE_SELECTOR          = '#elc-rate';
export const DAILY_SAMPLES_SELECTOR = '#elc-daily-samples';

export default class SamplesData extends Component {
  constructor(props = {}) {
    super(props);

    this.$samplesDataContainer = $(SAMPLES_DATA_CONTAINER);
    this.$samplesDataContainer.hide();

    this.store.events.subscribe('refreshSamplesData', (data) => this.refreshSamplesData(data));
    this.store.events.subscribe('clearSamplesData', () => this.refreshSamplesData({forcedValue: ' '}));
    this.store.events.subscribe('hideSamplesData', () => this.$samplesDataContainer.hide());
    this.store.events.subscribe('showSamplesData', () => this.$samplesDataContainer.show());
  }

  refreshSamplesData(data = {}) {
    const formatter = formatterFor('integer');

    $(NUMERATOR_SELECTOR).html(data.forcedValue || formatter(this.store.state.numerator));
    $(DENOMINATOR_SELECTOR).html(data.forcedValue || formatter(this.store.state.denominator));
    $(RATE_SELECTOR).html(data.forcedValue || formatterFor('percentage')(this.store.state.rate));
    $(DAILY_SAMPLES_SELECTOR).html(data.forcedValue || formatter(this.store.state.dailySamples));
  }

  formattedRate = (rate) => {
    // Rate received in decimal form: e.g. 0.2344341312...
    if (rate != null) {
      return (parseFloat(rate) * 100).toFixed(3);
    } else {
      return '0.000';
    }
  };
}
