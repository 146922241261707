import { drag, select } from 'd3';
import { formattedDateTime } from '../utils/display';

class DateBar {
  DRAG_TARGET_SELECTORS = [
    '.timeline__date-bar',
    '.timeline__date-bar-label-container',
    '.timeline__date-bar-drag-handle'
  ]

  CURSOR_ANIMATION_TARGET_SELECTORS =
    this.DRAG_TARGET_SELECTORS.concat(['.timeline__date-bar-label-date', ".timeline__date-bar-label-data"])

  constructor({ root, scale, initialData, layout }) {
    this.root          = root;
    this.scale         = scale;
    this.data          = initialData;
    this.dataLabel     = "";
    this.layout        = layout;

    this.currentTimeAtBarPosition = new Date();
  }

  drawBar = () => {
    this.root.append("div")
      .attr("class", "timeline__date-bar")
      .style("margin-left", () => `${this.scale(this.currentTimeAtBarPosition) + this.layout.chartLeftMarginOffset}px`)
  }

  drawLabel = () => {
    const labelContainer =
      this.root.append("div")
        .attr("class", "timeline__date-bar-label-container")
        .style("margin-left", () => `${this.scale(this.currentTimeAtBarPosition) + this.layout.chartLeftMarginOffset * 0.5}px`)

    labelContainer.append("p")
      .attr("class", "timeline__date-bar-label-date")
      .text(() => formattedDateTime(this.currentTimeAtBarPosition));

    labelContainer.append("p")
      .attr("class", "timeline__date-bar-label-data")

    this.setDataLabel();
    this.updateLabelText();
  }

  setDataLabel() {
    this.dataLabel = $(this.rootNode()).data("date-bar-label");
  }

  updateLabelText() {
    select(this.timelineContainerNode().querySelector('.timeline__date-bar-label-data'))
      .text(() =>
        `${this.calculateData(this.currentTimeAtBarPosition)} ${this.dataLabel}${this.additionalContextLabelFor(this.currentTimeAtBarPosition)}`)
  }

  drawDragHandle = () => {
    this.root.append("div")
      .attr("class", "timeline__date-bar-drag-handle")
      .style(
        "margin-left", () => `${this.scale(this.currentTimeAtBarPosition) + this.layout.chartLeftMarginOffset * 0.5}px`
      )
  }

  roundDateToHour(date) {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
  }

  rootNode() {
    return this.root.nodes().pop();
  }

  timelineContainerNode() {
    return this.rootNode().closest('.timeline__container')
  }

  fetchAndUpdateData = async () => {
    let formObject = $(this.rootNode()).closest('.timeline__container').find('.experiment-timeline-form')
    if (formObject.length == 0) {
      formObject = $('.experiment-timeline-form').first();
    }

    const url    = $(this.rootNode()).data("date-bar-data-url");
    const params = formObject.serialize();
    const response = await $.getJSON(url, params);

    this.data = response.date_bar;
  }

  calculateData = (date) => {
    const targetTimestamp = this.roundDateToHour(date).getTime();

    let result = this.data[0][1];
    for (let i = 1; i < this.data.length; i++) {
      if (targetTimestamp >= this.data[i][0]) {
        result = this.data[i][1]
      }
    }
    return result;
  }

  additionalContextLabelFor = (date) => {
    const targetTimestamp = this.roundDateToHour(date).getTime();
    const additionalContextPresent = this.data[0].length > 2;
    let result = "";

    if (!additionalContextPresent) {
      return result;
    }

    result = this.data[0][2];
    for (let i = 1; i < this.data.length; i++) {
      if (targetTimestamp >= this.data[i][0]) {
        result = this.data[i][2]
      }
    }
    return ` (${result.length ? result : "No experiments running."})`;
  }

  setDragBehavior = () =>
    this.DRAG_TARGET_SELECTORS.forEach((target) =>
      select(this.timelineContainerNode().querySelector(target)).call(drag().on("start", this.dragStartCb).on("drag", this.dragCb).on("end", this.dragEndCb))
    )

  dragStartCb = () => {
    this.CURSOR_ANIMATION_TARGET_SELECTORS.forEach((target) => {
      this.timelineContainerNode().querySelector(target).style.cursor = "grabbing"
    })
  }

  dragEndCb = () => {
    this.CURSOR_ANIMATION_TARGET_SELECTORS.forEach((target) => {
      this.timelineContainerNode().querySelector(target).style.cursor = "grab"
    })
  }

  dragCb = (event) => {
    const currentPositions = {};

    this.DRAG_TARGET_SELECTORS.forEach((target) => {
      const selection = select(this.timelineContainerNode().querySelector(target));

      currentPositions[`${target}`] = parseFloat(selection.style("margin-left"));
      selection.style("margin-left", `${currentPositions[`${target}`] + event.dx}px`)
    });

    this.currentTimeAtBarPosition =
      this.scale.invert(currentPositions['.timeline__date-bar'] - this.layout.chartLeftMarginOffset)

    const labelDateNextText = formattedDateTime(this.roundDateToHour(this.currentTimeAtBarPosition));
    select(this.timelineContainerNode().querySelector(".timeline__date-bar-label-date")).text(() => labelDateNextText);

    this.updateLabelText();
  }

  draw = async (startsAt, endsAt) => {
    await this.fetchAndUpdateData();

    this.currentTimeAtBarPosition = new Date();

    if (this.currentTimeAtBarPosition < startsAt || this.currentTimeAtBarPosition > endsAt) {
      this.currentTimeAtBarPosition = startsAt;
    }

    this.drawBar();
    this.drawLabel();
    this.drawDragHandle();
    this.setDragBehavior();
    this.updateLabelText();
  }
}

export default DateBar;
