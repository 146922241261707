import SortableForm from "./utils/sortable_form";
import updateTreatmentDropdowns from "../utils/update_treatment_dropdowns";

class GridItemForm {
  constructor(gridItem) {
    this.gridItem = gridItem;
    this.$widget = this.gridItem.$widget;
    this.editUrl = this.$widget.data("edit-url");
    this.isOpen = false;
  }

  refresh = () => {
    $.get(this.editUrl, (html) => {
      $(`#${this.$widget.attr("aria-describedby")} .popover-body`).html(html);

      $(document).trigger("popover:load");

      this._setSortableBehavior();
      this._disableWidgetFilterValuesForActiveFilters();
    });
  };

  open = () => {
    $.get(this.editUrl, (html) => {
      this.$widget
        .popover({
          trigger: "manual",
          placement: "bottom",
          fallbackPlacement: ["bottom"],
          container: ".dashboard .popover-container",
          viewport: ".dashboard .popover-container",
          content: html,
          html: true,
        })
        .popover("show");

      this.isOpen = true;

      $(document).trigger("popover:load");

      this.$form = $(`#${this.$widget.attr("aria-describedby")}`);
      this._setup();
      this._setSortableBehavior();
    });
  };

  close = () => {
    if (this.isOpen) {
      this._unsetSortableBehavior();
      this.$widget.popover("dispose");
      this.isOpen = false;
    }
  };

  _setup = () => {
    this._registerSortableFormCallbacks();
    this._registerFormDismissClickHandler();
    this._registerWidgetFilterSelect2ClearAfterSelectionCallback();
    this._registerQueryFilterChangeHandler();
    this._registerTreatmentChangeHandler();
    this._registerActiveFilterCallbacks();
    this._disableWidgetFilterValuesForActiveFilters();
  };

  _registerFormDismissClickHandler = () =>
    this.$form.on("click", ".dismiss-edit-portlet", (e) => {
      e.preventDefault();

      if (this.isOpen) {
        this.gridItem.disableEditMode();
      }
    });

  _registerWidgetFilterSelect2ClearAfterSelectionCallback = () =>
    this.$form.on('select2:select', '.add-widget-filter select', (e) => {
      const $widgetFilterSelect = $(e.currentTarget)
      const selectedFilterType = $widgetFilterSelect.val()[0];

      // trigger Cocoon to show the new filter
      if (selectedFilterType) {
        $(`a[data-widget-filter-key=${selectedFilterType}]`).trigger('click')
      }

      // clear selection from Filters select2
      $widgetFilterSelect.val(null).trigger('change')
    })

  _registerQueryFilterChangeHandler = () => {
    this.$form.on('change', '.widget-filters-list select.query_operator', (e) => {
      const $operatorSelect = $(e.target);

      if ($operatorSelect.val() == 'in') {
        $operatorSelect
          .closest('.nested-fields')
          .find('input[type="text"]')
          .attr('placeholder', 'semi-colon; separated; list of queries')
          .data('toggle', 'tooltip')
          .attr('title', '"semi-colon; separated; list of queries". Does not work for queries that contain a semi-colon.')
          .tooltip();
      } else {
        $operatorSelect
          .siblings('input')
          .attr('placeholder', 'Query')
          .tooltip('dispose');
      }
    });
  }

  _registerTreatmentChangeHandler = () => {
    this.$form.on('change', '.ab-treatment-configuration .ab-experiment-select', (e) => updateTreatmentDropdowns(e.target));
  }

  _registerActiveFilterCallbacks = () => {
    this.$form.on('change', '.widget-filters-list .operator', (e) => {
      this._disableWidgetFilterValues(e.target);
    });
  }

  _disableWidgetFilterValuesForActiveFilters = () => {
    this.$form.find('.widget-filters-list .operator').each((_i, el) => {
      this._disableWidgetFilterValues(el);
    });
  }

  _disableWidgetFilterValues = (operatorSelect) => {
    const $operatorSelect = $(operatorSelect);
    const isActive = $operatorSelect.val() == 'is_active';
    const $dropdown = $operatorSelect
      .closest('.nested-fields')
      .find('select.model');

    $dropdown.prop('disabled', isActive);

    if (isActive) {
      $dropdown.val(null).trigger('change');
    }
  }
}

Object.assign(GridItemForm.prototype, SortableForm);

export default GridItemForm;
