class GridItemEditModeOverlay {
  constructor(gridItem) {
    this.$root = gridItem.$root.find(".portlet__edit-mode-overlay");
  }

  focus = () => this.$root.removeClass("portlet__edit-mode-overlay--unfocused");
  unfocus = () => this.$root.addClass("portlet__edit-mode-overlay--unfocused");

  lock = () => this.$root.addClass("portlet__edit-mode-overlay--locked");
  unlock = () => this.$root.removeClass("portlet__edit-mode-overlay--locked");
}

export default GridItemEditModeOverlay;
