
/*
   * Fix bug in select2 with jQuery 3.6.0's new nested-focus "protection"
   * search box was not focusing when opening a dropdown
   * 
   * see: https://github.com/select2/select2/issues/5993
   * see: https://github.com/jquery/jquery/issues/4382
   *
   * Recheck that this fix was included in future releases (rc 1 does not include it)
   */

$(document).on('select2:open', () => {
  document.querySelector('.select2-container--open .select2-search__field').focus();
});
