import formatterFor from '../formatter_for';

class RadarChartTooltip {
  constructor(chartData) {
    this.series = chartData.series
    this.measureLabels = chartData.meta.measure_labels
    this.formatters = chartData.meta.measure_formats.map(formatterFor)
  }

  _setContent = (dimensionIndex, measureIndex, viewContext) => ({
    tooltipTitle: this.measureLabels[measureIndex],
    markerColor: viewContext.globals.colors[dimensionIndex],
    dimensionName: this.series[dimensionIndex].name,
    measureValue: this._formattedMeasureValue(dimensionIndex, measureIndex),
  })

  _formattedMeasureValue = (dimensionIndex, measureIndex) =>
    this.formatters[measureIndex](this.series[dimensionIndex].data[measureIndex])

  render = ({ _series, seriesIndex, dataPointIndex, w }) => {
    const content = this._setContent(seriesIndex, dataPointIndex, w);

    return (`
      <div class="apexcharts-tooltip-title">${content.tooltipTitle}</div>
      <div class="apexcharts-tooltip-series-group apexcharts-active">
        <span class="apexcharts-tooltip-marker" style="background-color: ${content.markerColor}"></span>
        <div class="apexcharts-tooltip-series-text">
          <div class="apexcharts-tooltip-text-label">
            ${content.dimensionName}: <strong>${content.measureValue}</strong>
          </div>
        </div>
      </div>
    `)
  }
}

export default RadarChartTooltip;
