/**
 * Function to calculate the minimum and maximum values in an array of numbers
 *
 * @param {number[]} array an array of numbers
 * @param {number} [startingMin=Infinity]  value returned as the maximum if no value is bigger than startingMin
 * @param {number} [startingMax=-Infinity] value returned as the minimum if no value is smaller than startingMax
 * @returns an array with the smallest number in `array` as the first value and the highest
 *          number in `array` as the second value
 */
export function minMax(array, startingMin = Infinity, startingMax = -Infinity) {
  let min = startingMin, max = startingMax

  for (const elt of array) {
    if (elt > max) max = elt
    if (elt < min) min = elt
  }

  return [min, max]
}
