$(document).on("cocoon:before-insert", (_e, _item, originalEvent) => {
  if ($(originalEvent.target).is('[disabled=disabled]')) {
    e.preventDefault();
  }
});

$(document).on('turbo:load', function () {
  $('.add_fields[disabled=disabled]').each((_, button) => {
    $(button).addClass('disabled');
  })
});
