import Component from '../../store/lib/component.js';

export const DATA_MEASURES_SELECTOR = '#elc-data-measures';
export const DATA_MEASURES_DESCRIPTION_SELECTOR = '#elc-data-measure-description';
export const URL_PARAMETER = 'measure';

export default class MeasuresSelector extends Component {
  constructor(props = {}) {
    super({
      ...props,
      urlParameter: URL_PARAMETER
    });

    this.$measureDescription = $(DATA_MEASURES_DESCRIPTION_SELECTOR);
    this.$measuresSelect = $(DATA_MEASURES_SELECTOR);

    this.store.events.subscribe('populateMeasures', () => this.populateMeasures());
    this.store.events.subscribe('clearMeasures', () => this.clearMeasures());
    this.store.events.subscribe('measureSelected', () => this.onMeasureSelected());

    this.$measuresSelect.on('change', (e) => {
      let measureValue = $(e.target).val();
      if (measureValue === 'null') { measureValue = null; }

      this.changeMeasure(measureValue);
      this.dataStorage.addOrModify(this.urlParameter, measureValue);
    });

    const value = this.dataStorage.get(this.urlParameter);
    if (value != null && value != 'null') {
      this.store.commit('measure', value);
    }
  }

  initialize() {
    const val = this.store.state.measure;

    if (val != null && val !== 'null') {
      this.$measuresSelect.val(val).trigger('change');

      this.changeMeasure(val);
    }
  }

  changeMeasure(val) {
    this.store.commit('measure', val);

    this.store.events.publish('updateStateFromCalculatorRun', {
      numerator: null,
      denominator: null,
      rate: null,
      dailySamples: null,
      chartData: null,
      deltaMethodFactor: 1.0
    });

    // Once we have a measure we can run the calculator
    this.store.events.publish('fetchSamplesData');
    this.store.events.publish('measureSelected');
  }

  populateMeasures() {
    const measures = this.store.state.measures;
    let defaultMeasure;

    this.$measuresSelect.empty();

    if (measures.length > 0) {
      this.$measuresSelect.prop('disabled', false);
      this.$measuresSelect.append(new Option('Select KPI being optimized in experiment…', null));

      if (this.store.state.initializationMode) {
        defaultMeasure = {id: this.store.state.measure };
      } else {
        defaultMeasure = measures.find((measure) => measure.default) || measures[0];
      }

      measures.forEach((measure) => {
        const newOption = new Option(measure.name, measure.id, false, measure.id === defaultMeasure.id);
        this.$measuresSelect.append(newOption);
      });

      this.changeMeasure(defaultMeasure.id);
      this.dataStorage.addOrModify(this.urlParameter, defaultMeasure.id);
    } else {
      this.$measuresSelect.prop('disabled', true);
      this.$measuresSelect.append(new Option('No measures available', null, true, true));
      this.$measureDescription.html('This data source does not define any KPIs that can be used for experiment sizing');
    }
  }

  clearMeasures() {
    this.changeMeasure(null);
    this.dataStorage.delete(this.urlParameter);

    this.$measuresSelect.empty();
    this.$measuresSelect.prop('disabled', true);
  }

  onMeasureSelected() {
    const measure = this.currentMeasureObject();

    if (measure) {
      this.$measureDescription.html(measure.description);
    } else {
      this.$measureDescription.html('');
    }
  }

  currentMeasureObject() {
    if (!this.store.state.measures) { return null; }
    if (!this.store.state.measure) { return null; }

    return this.store.state.measures.find(m => m.id === this.store.state.measure);
  }
}
