class AvailableTraffic {
  constructor(el) {
    this.$el = $(el);
    this.refreshUrl = this.$el.data("refresh-url");
  }

  refresh(timeframe) {
    if (timeframe.starts_at && timeframe.ends_at) {
      $.get(this.refreshUrl, { available_traffic: timeframe }).done((html) => {
        this.$el.html(html);
      });
    }
  }
}

export default AvailableTraffic;
