import { format } from "d3";

function createChart($el, values) {
  const nonZeroValues = values.filter(e => +e.size > 0);

  const options = {
    series: [{
      data: nonZeroValues.map(e => [+e.x, +e.y, +e.size])
    }],
    chart: {
      type: "bubble",
      height: "100%",
      animations: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ['var(--bs-blue)'],
    dataLabels: {
        enabled: false
    },
    plotOptions: {
      bubble: {
        minBubbleRadius: 5
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    title: {
      text: "Baseline vs. Comparison Score by Input (Δ > 0)",
      align: "center"
    },
    subtitle: {
      text: "Click markers to view input comparisons",
      align: "center"
    },
    markers: {
      strokeWidth: 1,
      onClick: (e) => {
        const dataPointIndex = $(e.target).attr("j");
        const dataPoint = nonZeroValues[dataPointIndex];
        const target = e.metaKey ? "_blank" : "_self";

        window.open(dataPoint.url, target);
      }
    },
    xaxis: {
      type: "numeric",
      min: 0,
      max: 1000,
      tickAmount: 5,
      tooltip: {
        enabled: false
      },
      labels: {
        formatter: format(",")
      },
      title: {
        text: "Baseline Score"
      }
    },
    yaxis: {
      min: 0,
      max: 1000,
      tickAmount: 5,
      labels: {
        formatter: format(",")
      },
      title: {
        text: "Comparison Score"
      }
    },
    tooltip: {
      custom: ({ dataPointIndex }) => {
        const dataPoint = nonZeroValues[dataPointIndex];

        return `
          <div class="apexcharts-tooltip-title">${dataPoint.label}</div>
          <div class="apexcharts-tooltip-series-group apexcharts-active">
            <dl class="apexcharts-tooltip-text row no-gutters mb-0">
              <dt class="col-6 pe-1">Baseline</dt>
              <dd class="col-6 ps-1">${format(",")(dataPoint.x)}</dd>

              <dt class="col-6 pe-1">Comparison</dt>
              <dd class="col-6 ps-1">${format(",")(dataPoint.y)}</dd>

              <dt class="col-6 pe-1">Δ</dt>
              <dd class="col-6 ps-1">${format("+,")(dataPoint.diff)}</dd>
            </dl>
          </div>
        `
      }
    }
  };

  const chart = new ApexCharts($el.get(0), options);

  chart.render();
  $(document).one("turbo:before-cache", () => chart.destroy());
}

function loadDifferencesByPair() {
  const $el = $("#differences-by-pair");

  if ($el.length) {
    $.getJSON($el.data("url"), (values) => createChart($el.find(".chart"), values));
  }
}

$(document).on("turbo:load", loadDifferencesByPair);
