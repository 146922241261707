import fuzzyMatch from "utils/fuzzy_match";

class Search {
  constructor(list, value) {
    this.$list = $(list);
    this.value = value;
  }

  call() {
    if (this.value.length) {
      this.$list.hide().filter((_i, el) => {
        const [matched] = fuzzyMatch($(el).data("search"), this.value);
        return matched;
      }).show();
    } else {
      this.$list.show();
    }
  }
}

export default Search;
