import ApexCharts from "apexcharts";

import LineChartDashboardTemplateOptions from "apex/utils/chart_options/line_chart_dashboard_template_options";
import WithWidgetOptions from "apex/utils/chart_options/extenders/with_widget_options";

$(document).on("turbo:load", () => {
  $(".dashboard-template__chart").each((_, el) => {
    const data = $(el).data("chart-data");

    if (Object.keys(data.lines)?.length) {
      const options = new WithWidgetOptions(data, LineChartDashboardTemplateOptions).build();
      const chart = new ApexCharts(el, options);

      chart.render();
      $(document).one("turbo:before-cache", () => chart.destroy());
    } else {
      const message = data.meta?.error_message || 'No data available';

      $(el).empty().append(`
        <div class="d-flex align-items-center justify-content-center p-5">
          <p class="lead">${message}</p>
        </div>
      `);
    }
  });
});
