/** A new experiment data source was selected */
export const EXPERIMENT_DATA_SOURCE_CHANGED = 'experimentDataSource:changed'
/** The filter type was toggled */
export const FILTER_TYPE_CHANGED = 'filterType:changed'
/** A Result Filter section was added */
export const RESULT_FILTERS_ADDED = 'resultFilters:added'
/** A result Filter section was removed */
export const RESULT_FILTERS_REMOVED = 'resultFilters:removed'
/** The a widget filter was selected in the dropdown */
export const WIDGET_FILTER_CHANGED = 'widgetFilter:changed'
/** The collection of relevant widget filters has been updated (loaded from the server) */
export const WIDGET_FILTERS_UPDATED = 'widgetFilters:updated'
/** Widget filter values were loaded */
export const WIDGET_FILTER_VALUES_UPDATED = 'widgetFilterValues:updated'
/** The filter type has changed to `widget_filter` */
export const WIDGET_FILTER_TYPE_SELECTED = 'widgetFilterType:selected'
/** The filter type has changed to somethign different than `widget_filter` */
export const WIDGET_FILTER_TYPE_UNSELECTED = 'widgetFilterType:unselected'
