import Component from '../../store/lib/component.js';

export const TRAFFIC_PER_TREATMENT_SELECTOR = '#elc-traffic-per-treatment__value';
export const TRAFFIC_PER_TREATMENT_SLIDER_SELECTOR = '#elc-traffic-per-treatment__slider';
export const URL_PARAMETER = 'traffic-per-treatment';

export default class TrafficPerTreatment extends Component {
  constructor(props = {}) {
    super({
      ...props,
      urlParameter: URL_PARAMETER
    });

    $(TRAFFIC_PER_TREATMENT_SELECTOR).on('change keyup input', (e) => {
      const adjustedValue = parseFloat($(e.target).val()).toFixed(2);
      if (parseFloat($(e.target).val()) > adjustedValue) {
        $(e.target).val(adjustedValue);
      }
      const val = parseFloat($(e.target).val());
      $(TRAFFIC_PER_TREATMENT_SLIDER_SELECTOR).val(val);
      this.store.commit('trafficPerTreatment', val );
      this.store.events.publish('runCalculator');
      this.dataStorage.addOrModify(this.urlParameter, $(e.target).val());
    });

    $(TRAFFIC_PER_TREATMENT_SLIDER_SELECTOR).on('input', (e) => {
      const val = parseFloat($(e.target).val());
      $(TRAFFIC_PER_TREATMENT_SELECTOR).val(val);
      this.store.commit('trafficPerTreatment', val);
      this.store.events.publish('runCalculator');
    });

    $(TRAFFIC_PER_TREATMENT_SLIDER_SELECTOR).on('change', (e) => {
      // Using change to avoid clutter on browser history state
      this.dataStorage.addOrModify(this.urlParameter, $(e.target).val());
    });
  }

  initialize() {
    const val = parseFloat(this.dataStorage.get(this.urlParameter));

    if (!isNaN(val)) {
      this.store.commit('trafficPerTreatment', val );
      $(TRAFFIC_PER_TREATMENT_SELECTOR).val(val);
      $(TRAFFIC_PER_TREATMENT_SLIDER_SELECTOR).val(val);
    }
  }
}
