import { axisBottom, timeFormat, timeMonday } from 'd3';

class Axis {
  DEFAULT_LAYOUT = { axisHeight: 20 }

  constructor(options) {
    this.root = options.root;
    this.scale = options.scale;
    this.chartWidth = options.chartWidth;
    this.layout = Object.assign({}, this.DEFAULT_LAYOUT);
  }

  draw = () => {
    this.root.append("svg")
      .attr("width", this.chartWidth)
      .attr("height", this.layout.axisHeight)
      .attr("class", "timeline__x-axis")
      .call(axisBottom().scale(this.scale).ticks(timeMonday).tickFormat(timeFormat("%b %d")))
  }
}

export default Axis;
