$(document).on('turbo:load', () => {
  $(document).on('select2:select', '.add-widget-filter select', (e) => {
    const $widgetFilterSelect = $(e.currentTarget)
    const selectedFilterType = $widgetFilterSelect.val()[0];

    // trigger Cocoon to show the new filter
    if (selectedFilterType) {
      $(`a[data-widget-filter-key=${selectedFilterType}]`).trigger('click')
    }

    // clear selection from Filters select2
    $widgetFilterSelect.val(null).trigger('change')
  })
})
