$(document).on('change', '#portlet_widget_attributes_data_source_id', function (e) {
  const id = $(e.target).val()

  $('.data-source-details, .widget-type-details').addClass('d-none')

  const details = $("#data-source-details");

  details.find('.data-source-tabs').addClass('d-none');
  details.find('.pre-tabs-view').removeClass('d-none');

  details.removeClass('d-none')

  $('#portlet_widget_type').val('')
    .find('option')
      .hide()
      .attr('disabled', true)
    .end()
    .find("option[data-valid-data-sources~=\"" + id + "\"]")
      .show()
      .removeAttr('disabled')
    .end()

  const urlTemplate = details.data('url')

  if (urlTemplate) {
    const dataSourceTabsUrl = urlTemplate.replace('data_source_id', id)
    $.get(dataSourceTabsUrl).done(function (data, _status, _jqxhr) {
      details.find('.pre-tabs-view').addClass('d-none');
      details.find('.data-source-tabs').replaceWith(data);
      details.tooltip({ selector: '[data-bs-toggle="tooltip"]' });
    }).fail(function (_jqXHR, _textStatus, _errorThrown) {
      details.find('.loading').addClass('d-none');
      details.find('.error').removeClass('d-none');
    });
  }
});

$(document).on('change', '#portlet_widget_type', function (e) {
  const key = $(e.target).find('option:selected').data('key');

  $('.widget-type-details').addClass('d-none');
  $(`#widget-type-details-${key}`).removeClass('d-none');
});

function fetchLpaExamplesForLpaReadout(e) {
  const readoutId = $(e.target).val()
  const lpaExampleSelect = $('#lpa-example-select')

  $.getJSON("/lpa/readouts/" + readoutId + "/examples").done(function(data) {
    lpaExampleSelect.empty()

    $.each(data, function (i, item) {
      lpaExampleSelect.append($('<option>', {
        value: item.id,
        text : item.input_name
      }))
    })

    $('#lpa-example-field').removeClass('d-none')
  })
}

$(document).on('change', '#lpa-readout-field', fetchLpaExamplesForLpaReadout);
