$(document).on('turbo:load', function () {
  $('.app__screenshot').on('click', function(e) {
    const currentScreenshot = $(this);
    const commandKeyIsPressed = e.metaKey;

    if (commandKeyIsPressed) {
      window.open(currentScreenshot.prop('src'))
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    const currentScreenshotHasPortraitOrientation = currentScreenshot.height() > currentScreenshot.width();
    if (currentScreenshotHasPortraitOrientation) {
      currentScreenshot.toggleClass('app__screenshot--enlarged-portrait');
    } else {
      currentScreenshot.toggleClass('app__screenshot--enlarged-landscape');
    }
  })
});
