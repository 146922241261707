import Component from '../../store/lib/component.js';

export const IMPACT_PERCENTAGE_SELECTOR = '#elc-impact-percentage__value';
export const URL_PARAMETER = 'impact-percentage';

export default class ImpactPercentage extends Component {
  constructor(props = {}) {
    super({
      ...props,
      urlParameter: URL_PARAMETER
    });

    this.$impactPercentage = $(IMPACT_PERCENTAGE_SELECTOR);

    const self = this;

    this.$impactPercentage.on('change keyup input', (e) => {
      self.store.commit('impactPercentage', parseFloat($(e.target).val()));
      self.store.events.publish('runCalculator');
    });

    this.$impactPercentage.on('change', (e) => {
      self.dataStorage.addOrModify(self.urlParameter, $(e.target).val());
    });
  }

  initialize() {
    const val = parseFloat(this.dataStorage.get(this.urlParameter));

    if (!isNaN(val)) {
      this.store.commit('impactPercentage', val);
      this.$impactPercentage.val(val);
    }
  }
}
