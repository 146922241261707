import { select } from 'd3';
import { formattedDate } from '../utils/display';

class Modal {
  DATA_TARGETS = ['key', 'name', 'starts_at', 'ends_at', 'show_url', 'status', 'percent_of_traffic', 'storefront_country_codes', 'area', 'treatments_count', 'dashboard_url']

  constructor(options) {
    this.entries = options.entries;
    this.dataTargetsHTML = this.DATA_TARGETS.map((attr) => attr.replace(new RegExp(/_/, 'g'), "-"));

    this.openEntry = undefined;
    this.modalShowLink = select('.timeline__modal-show-link');
    this.modalDashboardLinkWrapper = select('.timeline__modal-data-dashboard-link-wrapper');
  }

  writeDataAttributesToEntries = () => {
    this.entries
      .attr("data-bs-toggle", "modal")
      .attr("data-bs-target", "#timeline__entry-modal")

    this.DATA_TARGETS.forEach(
      (target, i) => {
        this.entries.attr(`data-${this.dataTargetsHTML[i]}`, (d) => d[`${target}`])
      }
    )
  }

  formattedText = (dataType) => {
    const rawContent = this.openEntry.attr(`data-${dataType}`)

    switch (dataType) {
      case "starts-at":
      case "ends-at":
        return formattedDate(new Date(rawContent));
      default:
        return rawContent;
    }
  }

  populateModalText = () => this.dataTargetsHTML.forEach((target) => {
    select(`.timeline__modal-data--${target}`).text(() => this.formattedText(target))
  })

  setLinkBehavior = () => {
    this.modalShowLink
      .attr("href", this.openEntry.attr("data-show-url"))

    if (this.openEntry.attr('data-dashboard-url')) {
      this.modalDashboardLinkWrapper.select('a').attr("href", this.openEntry.attr('data-dashboard-url'));
      this.modalDashboardLinkWrapper.classed('d-none', false);
    } else {
      this.modalDashboardLinkWrapper.classed('d-none', true);
    }
  }

  addHueToStatusContainer = () =>
    select(".timeline__modal-data-container--status")
      .attr("class", this.statusCSSClasses())

  statusCSSClasses = () =>
    `timeline__modal-data-container timeline__modal-data-container--status timeline__modal-data-container--${this.openEntry.attr("data-status").toLowerCase()}`

  setModalOpenState = () =>
    this.entries.nodes().forEach((node) => node.addEventListener("click", (e) => {
      this.openEntry = select(e.currentTarget);
      this.populateModalText();
      this.setLinkBehavior();
      this.addHueToStatusContainer();
    }))

  draw = () => {
    this.writeDataAttributesToEntries();
    this.setModalOpenState();
  }
}

export default Modal;
