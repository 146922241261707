import { merge } from 'lodash';

import BaseOptions from './base_options';

import formatterFor from 'apex/utils/formatter_for';
import annotationsFor from 'apex/utils/annotations_for';

import LineChartTooltip from 'apex/utils/custom_tooltips/line_chart_tooltip';
import LineChartDataNormalizer from 'apex/utils/data_normalizers/line_chart_data_normalizer';
import LineChartLegendClickHandler from "apex/utils/click_handlers/line_chart_legend_click_handler";

class LineChartOptions extends BaseOptions {
  static defaultOptions() {
    return merge(super.defaultOptions(), {
      chart: { type: 'line' },
      markers: { size: 0 },
      stroke: { width: 2 },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM yyyy',
            day: 'MMM d, yyyy',
            hour: 'HH:mm'
          }
        }
      },
      yaxis: { forceNiceScale: true }
    });
  }

  constructor(chartData) {
    super(chartData);

    this.legendClickHandler = new LineChartLegendClickHandler(this)
    this.normalizer = new LineChartDataNormalizer(this.chartData);
    this.tooltip = new LineChartTooltip(this.normalizer, this.chartData.meta);
    this.dataDependentOptions = this.setDataDependentOptions();
  }

  setDataDependentOptions = () =>
  ({
    annotations: {
      xaxis: annotationsFor(this.chartData.meta.annotations)
    },
    chart: {
      events: {
        click: this.legendClickHandler.call,
        mounted: (chart) => {
          const titleElement = chart.el.querySelector('.apexcharts-title-text');
          if (titleElement && this.chartData.meta.description) {
            titleElement.setAttribute('data-controller', 'bs--tooltip');
            titleElement.setAttribute('title', this.chartData.meta.description);
            titleElement.setAttribute('data-bs--tooltip-options-value', JSON.stringify({ html: true }));
          }
        }
      }
    },
    colors: this.chartData.meta.colors,
    series: this.normalizer.timeSortedSeries,
    title: {
      text: this.chartData.meta.title
    },
    tooltip: {
      custom: this.tooltip.render
    },
    xaxis: {
      title: {
        text: this.chartData.meta.x_axis_label
      }
    },
    yaxis: {
      labels: {
        formatter: formatterFor(this.chartData.meta.format)
      },
      title: {
        text: this.chartData.meta.y_axis_label
      },
      min: this.normalizer.yRange.min,
      max: this.normalizer.yRange.max
    }
  })
}

export default LineChartOptions;
