import Clipboard from 'clipboard';

$(document).on('brix:widgets:initialized', () => setPortletLinkIconClipboardBehavior())

const setPortletLinkIconClipboardBehavior = () => {
  const clip = new Clipboard(".portlet__icon.link");

  clip.on("success", (e) => {
    renderTooltipSuccessMessage($(e.trigger))
    restoreOriginalTooltipMessageOnMouseOut($(e.trigger))
  })
}

const renderTooltipSuccessMessage = ($el) =>
  $el.tooltip('hide').attr('title', $el.data("clipboard-success")).tooltip('_fixTitle').tooltip('show');

const restoreOriginalTooltipMessageOnMouseOut = ($el) =>
  $el.on("mouseout", () => $el.attr('title', $el.data("clipboard-restore")).tooltip('_fixTitle'))
