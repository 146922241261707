import ApexCharts from "apexcharts";
import { merge } from "lodash";

import formatterFor from "apex/utils/formatter_for";
import BaseWidget from "./base_widget";
import HorizontalBarChartOptions from "apex/utils/chart_options/horizontal_bar_chart_options";
import WithWidgetOptions from "apex/utils/chart_options/extenders/with_widget_options";

const defaultColors = {
  success: {
    positive: "var(--bs-success)",
    negative: "var(--bs-danger)",
    neutral:  "var(--bs-secondary)",
  },

  failure: {
    positive: "var(--bs-danger)",
    negative: "var(--bs-success)",
    neutral:  "var(--bs-secondary)",
  }
};

class ABExperimentChartReadoutWidget extends BaseWidget {
  constructor(attributes) {
    super(attributes);

    this.chart = null;
    this.latestData = null;
  }

  load = () => $.getJSON(this.attributes.dataUrl, this.updateChartData).fail(this.handleLoadFailure)

  handleLoadFailure = (xhr) => this.renderDataErrorMessage(xhr.responseJSON?.message || 'No data available')

  render = () => this.chart && this.chart.render()

  destroyChart = () => {
    this.chart && this.chart.destroy();
    this.chart = null;
  }

  buildChart = () => {
    const overrides = {
      colors: [this.significanceColor("success", "neutral")],
      chart: {
        events: {
          mounted: this.fixBarColors,
          updated: this.fixBarColors
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      tooltip: {
        enabled: true,
        custom: this.tooltipFormatter
      }
    };

    const options = merge(new WithWidgetOptions(this.latestData, HorizontalBarChartOptions).build(), overrides);

    this.chart = new ApexCharts(this.element.find(".chart").get(0), options);

    $(document).one("turbo:before-cache", () => this.chart.destroy());
  }

  registerSignificanceHandlers = () => {
    this.element
      .find(".toggle-significance")
      .removeClass("invisible")
      .find("input")
      .prop("checked", this.latestData.meta.significant_only)

    this.element.one("change", ".toggle-significance input", (e) => {
      $.rails.fire(e.target.closest("form.new_brix_ad_hoc_filter"), "submit");
    });

    this.element.one("ajax:success", "form.new_brix_ad_hoc_filter", (e) => {
      this.updateChartData(e.detail[0]);
    });
  }

  updateChartData = (data) => {
    this.destroyChart();

    if (data.series?.length) {
      this.latestData = data;

      this.registerSignificanceHandlers();
      this.buildChart();
      this.render();
    } else {
      this.renderDataErrorMessage(data.meta.error_message);
    }
  }

  isPresentation = () => $("body.presentation").length > 0

  significanceColor = (disposition, significance) => {
    return defaultColors[disposition][significance];
  }

  tooltipFormatter = ({ series, seriesIndex, dataPointIndex }) => {
    const meta = this.latestData.meta;

    const measureName = meta.measure_title_text;
    const measureValue = series[seriesIndex][dataPointIndex];

    const dimensionName = meta.dimension_title_text;
    const dimensionValue = meta.dimension_categories[dataPointIndex]

    const disposition = meta.disposition;
    const significance = meta.significances[dataPointIndex].significance;
    const outcome = meta.significances[dataPointIndex].outcome;

    return `
      <div class="apexcharts-tooltip-title">${measureName}</div>
      <div class="apexcharts-tooltip-series-group apexcharts-active ab-experiment-chart-readout-widget__tooltip">
        <dl class="apexcharts-tooltip-text row no-gutters mb-0">
          <dt class="col-6 pe-1">${dimensionName}</dt>
          <dd class="col-6 ps-1">${dimensionValue}</dd>

          <dt class="col-6 pe-1">Rel. Δ</dt>
          <dd class="col-6 ps-1">${formatterFor("percentage")(measureValue)}</dd>

          <dt class="col-6 pe-1">Outcome</dt>
          <dd class="col-6 ps-1">
            <span class="disposition-${disposition} significance-${significance}">
              ${outcome}
            </span>
          </dd>
        </dl>
      </div>
    `
  }

  fixBarColors = (chartContext) => {
    $(chartContext.el).find(".apexcharts-bar-area").each((_, el) => {
      const dataPointIndex = $(el).attr("j");
      const significance = this.latestData.meta.significances[dataPointIndex].significance;
      const color = this.significanceColor(this.latestData.meta.disposition, significance);

      $(el).attr("fill", color);
    })
  }
}

export default ABExperimentChartReadoutWidget;
